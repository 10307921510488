export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {

         },
         ENG : {
           "Rennen anlegen" : "Create a Race",
           "Rennen" : "Race",
           "Bitte Rennen eingeben" : "Please enter race",
           "Rennen darf nicht leer sein" : "The race must not be empty",
           "Rennen wurde angelegt" : "The race has been created",
           "aktuelles Rennen auswählen" : "Select Current Race",
           "Rennen auswählen" : "Select Race",
           "aktuelles Rennen" : "Current Race",
           "Auswahl" : "Selection",
           "ausgewählt" : "selected",
           "Rennen wurde erfolgreich ausgewählt" : "Race was successfully selected",
           "Reset" : "Reset",
         },
         FRA : {
           "Rennen anlegen" : "Lancer une Course",
           "Rennen" : "Course",
           "Bitte Rennen eingeben" : "Veuillez saisir la course",
           "Rennen darf nicht leer sein" : "La course ne peut pas être vide",
           "Rennen wurde angelegt" : "La course a été lancée",
           "aktuelles Rennen auswählen" : "Sélectionner la Course en Cours",
           "Rennen auswählen" : "Sélectionner la Course",
           "aktuelles Rennen" : "Course en Cours",
           "Auswahl" : "Sélection",
           "ausgewählt" : "sélectionnée",
           "Rennen wurde erfolgreich ausgewählt" : "La course a été sélectionnée",
           "Reset" : "Remettre",
         },
         ITA : {
           "Rennen anlegen" : "Inizia una Gara",
           "Rennen" : "Gara",
           "Bitte Rennen eingeben" : "Inserisci la gara",
           "Rennen darf nicht leer sein" : "La gara non può essere vuota",
           "Rennen wurde angelegt" : "La gara è iniziata",
           "aktuelles Rennen auswählen" : "Seleziona Gara Corrente",
           "Rennen auswählen" : "Seleziona Gara",
           "aktuelles Rennen" : "Gara Corrente",
           "Auswahl" : "Selezione",
           "ausgewählt" : "selezionato",
           "Rennen wurde erfolgreich ausgewählt" : "La gara è stata selezionata con successo",
           "Reset" : "Reset",
         },
         CZE : {
           "Rennen anlegen" : "Začněte Závod",
           "Rennen" : "Závod",
           "Bitte Rennen eingeben" : "Zadejte závod",
           "Rennen darf nicht leer sein" : "Závod nemůže být prázdný",
           "Rennen wurde angelegt" : "Závod byl vytvořen",
           "aktuelles Rennen auswählen" : "Vyberte Aktuální Závod",
           "Rennen auswählen" : "Vyberte Závody",
           "aktuelles Rennen" : "Aktuální Závod",
           "Auswahl" : "Výběr",
           "ausgewählt" : "vybraný",
           "Rennen wurde erfolgreich ausgewählt" : "Závod byl úspěšně vybrán",
           "Reset" : "Resetovat",
         },
         POL : {
           "Rennen anlegen" : "Rozpocznij Wyścig",
           "Rennen" : "Wyścig",
           "Bitte Rennen eingeben" : "Podaj wyścig",
           "Rennen darf nicht leer sein" : "Wyścig nie może być pusta",
           "Rennen wurde angelegt" : "Wyścig został stworzony",
           "aktuelles Rennen auswählen" : "Wybierz Bieżący Wyścig",
           "Rennen auswählen" : "Wybierz Wyścig",
           "aktuelles Rennen" : "Bieżący Wyścig",
           "Auswahl" : "Wybór",
           "ausgewählt" : "wybrany",
           "Rennen wurde erfolgreich ausgewählt" : "Wyścig został pomyślnie wybrany",
           "Reset" : "Zresetować",
         },
      }
    }
  },
}