const LPNotifications = {
  install(Vue, { store })
  {
    Vue.prototype.$notifications = {
      error: function(message) {
        const formatMessage = store.getters['formatError'];
        Vue.prototype.$toast.error(formatMessage(message));
      },
      success: function(message) {
        Vue.prototype.$toast.success(message);
      }
    };
  }
};

export default LPNotifications;