export const liveTimingMixin = {
  methods: {
    resolveText(content) {
      if (!content)
        return '';
      else
      {
        let result = '';
        for (let cell of content.elements)
        {
          if (cell.units)
          {
            for (let u of cell.units)
              if (u.unitType == "text")
                result = result + u.text;
          }
          else if (cell.text)
            result = result + cell.text;
        }
        return result;
      }
    },
  },
}