<template>
  <div class="LTTable">
    <b-table small striped :stacked="stacked" :class="[responsiveClass, basicDesignClass]" hover :sort-compare="sortCompare" :primary-key="primaryKey" :fields="fields()" :items="items" responsive="sm">
      <template v-slot:cell()="data">
        <span class="tdContent">
          <span :class="data.value.htmlClass">
            <LTCell :content="data.value" :parentTblStacked="stacked" />
          </span>
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import LTCell from "@/components/livetiming/LTCell.vue";

import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Results.js";
import { timingTranslationMixin } from "@/mixins/translations/live-timing/live-timing.js";

export default {
  mixins: [translationMixin, translationTableMixin, timingTranslationMixin],
  props: ["content", "meta"],
  components: {
    LTCell
  },
  data() {
    return {
      keyToMetaMap : {}
    }
  },
  methods: {
    resolveText(cell) {
      if (!cell)
        return '';
      else
      {
        if (cell.units)
        {
          let result = '';
          for (let u of cell.units)
            if (u.unitType == "text")
              result = result + u.text;
          return result;
        }
        else if (cell.text)
          return cell.text;
        else
          return '';
      }
    },
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      } 
      else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => toString(value[key]))
          .join(' ')
      } 
      else {
        return String(value)
      }
    },
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      if (this.keyToMetaMap[key] === 'rank')
      {
        const a = this.resolveText(aRow[key]);
        const b = this.resolveText(bRow[key]);
        const aInt = parseInt(a, 10);
        const bInt = parseInt(b, 10);
        if (!isNaN(aInt) && !isNaN(bInt))
        {
          const result = (aInt < bInt ? -1 : aInt > bInt ? 1 : 0);
          return result;
        }
        else if (!isNaN(aInt))
        {
          return -1;
        }
        else if (!isNaN(bInt))
        {
          return 1;
        }
        else
        {
           return this.toString(a).localeCompare(this.toString(b), compareLocale, compareOptions);
        }
      }
      else
        return null;
    },
    fields() {
       const result = this.fieldDefinitions;
       for (let fd of result)
          this.keyToMetaMap[fd.key] = fd.myOwnMetaInfo;
       return result;
    }
  },
  computed: {
    responsiveClass() {
      if (!this.stacked)
        return 'unstacked_table';
      return 'stacked_table';
    },
    basicDesignClass() {
      if (this.use_std_design)
        return 'std_design';
      return '';
    },
    items() {
      const primaryKeySource = this.primaryKeySourceKey;

      let result = [];
      if (this.content.datasets)
      {
        for (let ds of this.content.datasets)
          for (let r of ds.rows)
          {
            let row = {};
            for (let i in r.cells)
            {
              const key = "c" + i;
              row[key] = r.cells[i];
              if (key == primaryKeySource)
                row["ID"] = this.resolveText(r.cells[i]);
            }
            result.push(row);
          }
      }
      return result;
    },
    fieldDefinitions() {
      let result = [];
      /*let fieldDefinition = {
        key: "ID",
        label: "ID"
      }
      result.push(fieldDefinition);*/
      //console.log(this.content.head.rows);
      for (let r of this.content.head.rows)
      {
        for (let i in r.cells)
        {
          const title = this.translateTableHead(this.resolveText(r.cells[i]).trim());
          const key = "c"+i;
          let fieldDefinition = {
            key: key, 
            label: title,
            sortable: r.cells[i].sortable,
            myOwnMetaInfo: r.cells[i].meta
          };

          fieldDefinition.class = [];
          fieldDefinition.tdClass = [];
          if (r.cells[i].htmlClass && r.cells[i].htmlClass.trim() != '')
            fieldDefinition.class.push(r.cells[i].htmlClass);
          if (r.cells[i].meta && r.cells[i].meta.trim() != '')
            fieldDefinition.class.push('meta_' + r.cells[i].meta);
          result.push(fieldDefinition);

          //console.log(fieldDefinition);
        }
      }
      return result;
    },
    primaryKeySourceKey() {
      for (let fd of this.fieldDefinitions)
        if (fd.myOwnMetaInfo === "ID")
          return fd.key;
      return "";
    },
    primaryKey() {
      if (this.primaryKeySourceKey !== "")
        return "ID";
      return "";
    },
    stacked() {
      return (this.meta && this.meta.table_stacked === "true");
    },
    use_std_design() {
      return (!this.meta || this.meta.no_std_design !== "true");
    }
  }
}
</script>

<style>
.LTTable {
  text-align: left;
}

.stacked_table tbody > tr > [data-label]::before {
  width: 15% !important;
}

@media (min-width: 800px) {
  .LTTable table {
    font-size: 11pt;
  }

  .alternative_small {
    display: none;
  }

  .std_design td.meta_heatTime {
    min-width: 78px;
  }
}

@media (max-width: 799px) {
  .alternative_big {
    display: none;
  }
}

@media (max-width: 799px) {
  .LTTable table {
    font-size: 2vw;
  }

  .LTTable table img {
    width: auto;
    max-height: 2.7vw;
  }

  .meta_vehicle {
    display: none;
  }
}

.LTTable table th {
  text-align: center;
}

.meta_sectors table td {
  text-align: right;
  min-width: 70px;
}

.sector_head {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  text-align: center;
}

.sector_name {
  font-weight: bold;
  color: #111199;
}

.time_missing, .time_abort, .time_ontrack, .meta_sectors .contains_only_star {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  text-align: center;
}

.time_missing, .meta_sectors .contains_only_star {
  color: #999999;
}

.time_abort {
  color: red;
}

.time_ontrack {
  color: #AAAA00;
}

.std_design td.meta_totalTime {
  text-align: center;
  font-weight: bold;
  color: navy;
}

.std_design td.meta_heatTime {
  text-align: center;
}

.unstacked_table.std_design td.meta_rank .tdContent {
  display: inline-block;
  min-width: 30px;
  margin-right: 5px;
  text-align: right;
}

.std_design td.meta_ID {
  font-weight: bold;
  background: #DDDD00;
}

.unstacked_table.std_design td.meta_ID {
  text-align: center;
}

.std_design td.meta_name {
  font-weight: bold;
}
</style>