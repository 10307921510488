<template>
  <div>
    <b-table v-if="settings" :items="settings">
      <template #cell(lastping)="data">
        {{ data.value | formatTime('DD.MM.YYYY') }}<br>{{ data.value | formatTime('HH:mm:ss.SSS') }}
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      settings: null
    }
  },
  methods: {
    async getEventSettings() {
      const backend = this.$store.state.backend;
      const result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/admin/race/getEventSettings');
      if (result.status === 'ok') {
        if (result.data.status === 'ok')
        {
          delete result.data.settings["ID"];
          delete result.data.settings["StartingOrderClasses"];
          delete result.data.settings["StartingOrder"];

          switch (result.data.settings["TimeSource"]) {
            case 0:
              result.data.settings["TimeSource"] = 'Dateien (rdt)'; break;
            case 1:
              result.data.settings["TimeSource"] = 'Dateien (tdt)'; break;
            case 2:
              result.data.settings["TimeSource"] = 'Datenbank'; break;
            case 3:
              result.data.settings["TimeSource"] = 'automatisch'; break;
          }

          switch(result.data.settings["Mode"]) {
            case 0:
              result.data.settings["Mode"] = 'Test'; break;
            case 1:
              result.data.settings["Mode"] = 'Training'; break;
            case 2:
              result.data.settings["Mode"] = 'Rennen'; break;
          }

          this.settings = [result.data.settings];
        }
      }
    }
  },
  mounted() {
    this.getEventSettings();
  }
}
</script>