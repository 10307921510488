<template>
  <span class="number">
   {{ counter }}
  </span>
</template>

<script>
export default {
  name: "Counter",
  props: ["type"],
  data: () => {
    return {
    };
  },
  computed: {
    counter() {
      return this.$store.getters['statistics/counter'](this.type);
    }
  }
};
</script>

<style scoped>
span.number {
  color: red;
}
</style>
