<template>
  <div class="about main_bg">
    <pageTitle>{{ t('Impressum') }}</pageTitle>

    <div class="block">
     <div id="container">
      <div id="address">
        <table>
          <td style="vertical-align: middle; padding-left: 10px; padding-right: 10px;">
            <img src="../assets/bzn_logo.png" height="50" />
          </td>
          <td>
<h4><b>EDV- und Zeitnahmeservice Stoll GbR</b></h4>
{{ t('Vertretungsberechtigte') }}:<br>
Judith, Thomas {{ t('und') }} Michael Stoll
          </td>
        </table>
     </div>

     <div id="contact">
<span class="">
  <br>
  <table style="margin-left: 10px;">
    <tr><td colspan="2">Königsberger Str. 23</td></tr>
    <tr><td colspan="2">66606 <u>St. Wendel</u></td></tr>
    <tr><td colspan="2" style="height: 10px;"></td></tr>
    <tr><td style="width: 30px; "><font-awesome-icon icon="phone" /> </td><td>(06851) 8 20 79</td></tr>
    <tr><td><font-awesome-icon icon="mobile" /> </td><td>(0172) 6 87 17 21</td></tr>
    <tr><td><font-awesome-icon icon="envelope" /> </td><td>thomas(-at-)berg-zeitnahme.de</td></tr>
 </table>
<hr />
  <table style="margin-left: 10px;">
    <tr><td style="padding-right: 10px;">USt.-IdNr: </td><td>DE288167955</td></tr>
    <tr><td>SteuerNr.: </td><td>040/165/02753</td></tr>
 </table>
</span>
     </div>
    </div>
   </div>
  </div>
</template>

<script>
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/About.js";

export default {
  mixins: [translationMixin, translationTableMixin],
}
</script>

<style scoped>
h4 {
  text-align: center;
}

.about {
  display: block;
}

.block {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

#container {
  justify-content: space-around;
  align-items: center;
  flex-flow: column wrap;
  border: 1px dotted #CBCCDD;
  width: 80%
}

#address {
  /*display: table-cell;*/
  background: #EDEEFF;
}

#contact {
  /*display: table-cell;*/
  background: white;
}

</style>
