import Vue from "vue";
import { formMixin } from "./formMixin.js";

export const userDataFormMixin = Vue.util.mergeOptions(formMixin, {
  data() {
    return {
      prevRoute: null,
      goToPreviousPageOnSuccess: false,
      action: 'userLogin',
      successMessage: 'Sie wurden erfolgreich angemeldet!',
      failureMessage: 'Anmeldung fehlgeschlagen:'
    }
  },
  methods: {
    onActionAttempted(result) {
      if (result.status == 'ok')
      {
         this.$notifications.success(this.successMessage);
         if (this.goToPreviousPageOnSuccess && this.prevRoute != null)
           this.$router.push(this.prevRoute);
      }
      else
      {
         this.$notifications.error(this.failureMessage + "\n" + result.message);
      }
    },
    onSubmit(evt) {
      evt.preventDefault()
      if (this.submissionOk()) {
        this.$store.dispatch('userManagement/' + this.action, {
          data : this.form,
          onActionAttempted : this.onActionAttempted.bind(this)
        });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
});