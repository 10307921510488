<template>
  <div class="overviewcomponent arrived">
   <table>
     <tr>
       <td style="width: 20px;" class="img">
         <div class="img">
            <div><img src="../../assets/overview/ziel.png" /></div>
          </div>
       </td>
       <td style="width: 180px;">
         <OverviewTable :content="currentContent" :desc="true" :separateLastCell="true" />
       </td>
     </tr>
   </table>
  </div>
</template>

<script>
import OverviewTable from "@/components/OverviewTable.vue";

export default {
  components: {
    OverviewTable
  },
  data() {
    return {
    }
  },
  computed: {
    currentContent() {
      const result = JSON.parse(this.$store.getters['liveTiming/results']('Last5'));
      let table = null;
      if (result && result.groups.length > 0 && result.groups[0].subGroups.length > 0)
        table = result.groups[0].subGroups[0].table;
      return table;
    }
  }
}
</script>

<style scoped>
@import "../../assets/overview/overview.css";

.arrived {
  background-image: url(../../assets/overview/kleine_flagge.png);
}
</style>