import UserManagement from "../js-code/userManagement.js"

const moduleUser = {
  namespaced: true,

  state: {
    userManagement: null,
  },

  getters: {
    isInitialized: state => {
      return state.userManagement != null;
    },

    userLoggedIn: state => {
      return state.userManagement != null && state.userManagement.currentUser.loggedIn;
    },

    userIsAdmin: state => {
      return state.userManagement != null && state.userManagement.currentUser.loggedIn && state.userManagement.currentUser.isAdmin;
    },

    userHasRole: state => function(role) {
      return state.userManagement != null && state.userManagement.currentUser.loggedIn && state.userManagement.currentUser.hasRole(role);
    },

    userName: state => {
      if (state.userManagement && state.userManagement.currentUser.data.name)
        return state.userManagement.currentUser.data.name;
      else
        return 'unbekannt';
    },

    userKey: state => {
      return (state.userManagement) ? state.userManagement.currentUser.api_key : '';
    }
  },
  
  mutations: {
    setUserManagement(state, value) {
      state.userManagement = value;
    },
  },

  actions: {
    async init(context) {
      context.commit('setUserManagement', new UserManagement(context));
      context.state.userManagement.tryLogin();
    },

    async login(context, data) {
      const temp = await context.state.userManagement.login(data.data.email, data.data.password, data.data.rememberMe);
      data.onActionAttempted(temp);
    },

    async logout(context, onLogoutAttempted) {
      const temp = await context.state.userManagement.logout();
      onLogoutAttempted(temp);
    },

    async confirmRegistration(context, data) {
      const temp = await context.state.userManagement.confirmRegistration(data.data);
      data.onActionAttempted(temp);
    },

    async changePassword(context, data) {
      const temp = await context.state.userManagement.changePassword(data.data.oldPassword, data.data.newPassword1);
      data.onActionAttempted(temp);
    },

    async changeData(context, data) {
      const temp = await context.state.userManagement.changeData(data.data);
      data.onActionAttempted(temp);
    },

    async register(context, data) {
      const temp = await context.state.userManagement.register(data.data);
      data.onActionAttempted(temp);
    },

    async requestPasswordReset(context, data) {
      const temp = await context.state.userManagement.requestPasswordReset(data.data);
      data.onActionAttempted(temp);
    },

    async resetPassword(context, data) {
      const temp = await context.state.userManagement.resetPassword(data.data);
      data.onActionAttempted(temp);
    },
  }
}

export {
  moduleUser
};