import DownloadsClient from "../js-code/downloads.js";

var moduleDownloads = {
  namespaced: true,

  state: {
    client : null,
    items: ['blubb.txt'],
  },

  getters : {
    items : state => {
      return state.items;
    }
  },

  mutations : {
    setClient(state, value) {
      state.client = value;
    },

    setItems(state, value) {
      state.items = value;
    },
  },

  actions : {
    async init(context) {
      context.commit('setClient', new DownloadsClient(context));
    },
  },
};

export {
  moduleDownloads
}