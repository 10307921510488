import RealtimeClient from "./realtime_node.js";

export default class TickerClient {
  constructor(storeContext) {
    this.storeContext = storeContext;
    this.stateIndicator = -1;
    this.race = this.storeContext.rootGetters['race/currentParam'];
    if (this.race === undefined)
      this.race = 'Test!';
    this.maxItems = this.storeContext.getters['maxItems'];
    this.specifier = {race : this.race, maxEntries : this.maxItems};

    this.client = new RealtimeClient('/ticker', this, this.getBackend.bind(this));
  }

  getBackend()
  {
    return this.storeContext.rootState.backend;
  }

  getItems() {
    return this.storeContext.getters['items'];
  }

  async testUpdate()
  {
    let testStateIndicator = this.getItems()[3].id;

    return await this.client.getUpdates(this.specifier, testStateIndicator);
  }

  filter(items)
  {
    if (items.length <= this.maxItems)
      return items;
    return items.slice(0, this.maxItems); 
  }

  prepend(oldItems, newItems)
  {
    let result = oldItems;
    for (let element of newItems)
      result.unshift(element);
    return result;
  }

  getStandardSpecifier()
  {
    return this.specifier;
  }

  updateAll(specifier, stateIndicator, content)
  {
    if (this.specifier.race == this.race)
    {
      this.stateIndicator = stateIndicator;
      this.storeContext.commit('setItems', this.filter(content.reverse()));
    }
  }

  merge(specifier, previousIndicator, currentIndicator, content)
  {
    //console.log(content);
    if (this.specifier.race == this.race)
    {
      //console.log(this.stateIndicator);
      //console.log(previousIndicator);
      //console.log(currentIndicator);
      if (previousIndicator == this.stateIndicator)
      {
        //console.log('merge!');
        this.storeContext.commit('setItems', this.filter(this.prepend(this.getItems(), content)));
        this.stateIndicator = currentIndicator;
      }
      else
        return {merged: false, stateIndicator: this.stateIndicator};
    }

    return {merged: true};
  }

  handleError(e)
  {
    console.log(e);
  }
}