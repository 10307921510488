export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {
         },
         ENG : {
           "Besucher" : "visitors",
           "Besucher online" : "visitors online",
           "akt. Veranstaltung" : "current event",
           "im Chat" : "in the chatroom",
           "Stream" : "stream",
         },
         FRA : {
           "Besucher" : "visiteurs",
           "Besucher online" : "epreuve actuelle",
           "akt. Veranstaltung" : "visiteurs on-line",
           "im Chat" : "dans le tchat",
           "Stream" : "",
         },
         ITA : {
           "Besucher" : "visitatori",
           "Besucher online" : "visitatori online",
           "akt. Veranstaltung" : "evento attuale",
           "im Chat" : "nel chat",
           "Stream" : "",
         },
         CZE : {
           "Besucher" : "hostu",
           "Besucher online" : "hostu on line",
           "akt. Veranstaltung" : "akt. pøehled výsledkù",
           "im Chat" : "v chatovací místnosti",
           "Stream" : "",
         },
         POL : {
           "Besucher" : "odwiedzajacy",
           "Besucher online" : "odwiedzajacy on-line",
           "akt. Veranstaltung" : "biezace wydarzenie",
           "im Chat" : "w pokóju chatu",
           "Stream" : "transmisja na zywo",
         },
      }
    }
  },
}