import axios from 'axios';
const utf8 = require('utf8')

export default class User {
  api_key = ''
  data
  loggedIn = false
  isAdmin = false

  constructor(storeContext) {
    this.storeContext = storeContext;
  }

  static async post(url, data) {
    try {
      //console.log(process.env.VUE_APP_BACKEND_URL + url);
      const temp = await axios.post(process.env.VUE_APP_BACKEND_URL + url, data);
      return temp.data;
    }
    catch (e)
    {
      return {status: 'error', message: e};
    }
  }

  async get(url, email, password) {
    try {
      email = utf8.encode(email);
      password = utf8.encode(password);

      const temp = await axios.request({
        url: process.env.VUE_APP_BACKEND_URL + url,
        auth: {
          username: email,
          password: password
        }
      });
      //console.log(temp.data);
      return temp.data;
    }
    catch (e)
    {
      return {status: 'error', message: e};
    }
  }

  async getAuthorized(url) {
    const backend = this.storeContext.rootState.backend;
    const result = await backend.get(process.env.VUE_APP_BACKEND_URL + url);
    if (result.status == 'ok')
      return result.data
    return result;
  }

  async postAuthorized(url, data) {
    const backend = this.storeContext.rootState.backend;
    const result = await backend.post(process.env.VUE_APP_BACKEND_URL + url, data);
    if (result.status == 'ok')
      return result.data
    return result;
  }

  getErrorResult(result) 
  {
    console.log(result);
    const msgFormatter = this.storeContext.rootState.msgFormatter;
    const message = msgFormatter.format(result.message);
    return {status: 'error', message: message};
  }

  async loginViaAPIKey(apiKey)
  {
    if (apiKey && apiKey != '')
    {
      this.api_key = apiKey;
      let result = await this.getAuthorized('/user/loginKey');

      if (result.status == 'ok')
      {
        this.loggedIn = true;
        this.data = result.data;
        this.isAdmin = result.data.isAdmin;
        this.api_key = result.api_key;
      }
      else
        this.api_key = '';
    }
  }

  async login(email, password)
  {
    let result = await this.get('/user/login', email, password);

    if (result.status == 'ok')
    {
      this.loggedIn = true;
      this.data = result.data;
      this.isAdmin = result.data.isAdmin;
      this.api_key = result.api_key;

      return {status : 'ok', message : 'Sie wurden angemeldet'};
    }
    else
      return this.getErrorResult(result);
  }

  async logout()
  {
    let result = await this.getAuthorized('/user/logout');

    if (result.status == 'ok')
    {
      this.loggedIn = false;
      this.isAdmin = false;
      this.api_key = '';

      return {status : 'ok', message : 'Sie wurden abgemeldet!'}
    }
    else
      return this.getErrorResult(result);
  }

  async changePassword(oldPassword, newPassword)
  {
    let result = await this.postAuthorized('/user/changePassword', {
      oldPassword: oldPassword,
      newPassword: newPassword 
    });

    if (result.status == 'ok')
    {
      this.api_key = result.api_key;

      return {status : 'ok', message : 'Passwort wurde geändert'}
    }
    else
      return this.getErrorResult(result);
  }

  async changeData(data)
  {
    let result = await this.postAuthorized('/user/changeData', data);

    if (result.status == 'ok')
    {
      this.data = result.data;
      return {status : 'ok', message : 'Daten wurden geändert'}
    }
    else
      return this.getErrorResult(result);
  }

  hasRole(role)
  {
    return (this.data && this.data.roles && this.data.roles.includes(role));
  }
}