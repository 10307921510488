export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {

         },
         ENG : {
           "Sidebar verstecken" : "hide sidebar",
         },
         FRA : {
           "Sidebar verstecken" : "masquer sidebar",
         },
         ITA : {
           "Sidebar verstecken" : "nascondi sidebar",
         },
         CZE : {
           "Sidebar verstecken" : "skrýt sidebar",
         },
         POL : {
           "Sidebar verstecken" : "ukryj sidebar",
         },
      }
    }
  },
}