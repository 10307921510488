export const formMixin = {
  data() {
    return {
      show: true,
    }
  },
  methods: {
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.clearData();
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}