<template>
  <!--<div style="display: table">
    <div style="display: table-row">
      <div style="display: table-cell" v-for="(chart, index) in chartData" :key="index"><LiveTimingChart :chartData="chart.data" :options="chart.data.options" /></div>
    </div>
  </div>-->
  <b-tabs>
    <b-tab v-for="(chart, index) in chartData" :key="'chart'+index" :title="translateCLSTitle(chart.title)">
      <div style="display: flex; align-items: center; justify-content: center;">
        <LiveTimingChart class="chart" :chartData="chart.data" :options="chart.data.options" />
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import ClassificationHelper from "@/js-code/classification_helper.js";
import LiveTimingChart from "@/components/livetiming/LiveTimingBarChart";
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Results.js";
import { timingTranslationMixin } from "@/mixins/translations/live-timing/live-timing.js";

export default {
  mixins : [translationMixin, translationTableMixin, timingTranslationMixin],
  components: {
    LiveTimingChart
  },
  props : ['currentContent'],
  data() {
    /*return {
      sectorData: {
        labels: ['Sektor 1', 'Sektor 2', 'Sektor 3'],
        datasets: [
          {label: 'Fahrer 1', data: [2.5, 20.3, 10.5]},
          {label: 'Fahrer 2', data: [1.7, 19.2, 10.2]},
        ]
      }
    }*/
    return {
    }
  },
  computed : {
    chartData() {
      //console.log(new Date());
      return ClassificationHelper.createChartData(this.currentContent);
    }
  }
}
</script>

<style scoped>
@media (min-width: 800px) {
  .chart {
    width: 50%;
  }
}

@media (max-width: 799px) {
  .chart {
    width: 90%;
  }
}
</style>