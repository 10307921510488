export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {

         },
         ENG : {
           'alle' : 'all',
           'Vergleichen' : 'Compare',
           'Vergleich schließen' : 'Close Comparison',
           'Derzeit keine Daten vorhanden' : 'Currently no data available',
         },
         FRA : {
           'alle' : 'tous/toutes',
           'Vergleichen' : 'Comparer',
           'Vergleich schließen' : 'Fin de Comparaison',
           'Derzeit keine Daten vorhanden' : 'Aucune donnée actuellement disponible',
         },
         ITA : {
           'alle' : 'tutti/tutte',
           'Vergleichen' : 'Confrontare',
           'Vergleich schließen' : 'Fine del Confronto',
           'Derzeit keine Daten vorhanden' : 'Nessun dato attualmente disponibile',
         },
         CZE : {
           'alle' : 'všechny',
           'Vergleichen' : 'Srovnávat',
           'Vergleich schließen' : 'Konec Srovnání',
           'Derzeit keine Daten vorhanden' : 'Momentálně nejsou k dispozici žádná data',
         },
         POL : {
           'alle' : 'wszystkie',
           'Vergleichen' : 'Porównywać',
           'Vergleich schließen' : 'Koniec Porównania',
           'Derzeit keine Daten vorhanden' : 'Żadne dane nie są obecnie dostępne',
         },
      }
    }
  },
}
