import RealtimeClient from "./realtime_node.js";

export default class ChatClient {
  constructor(storeContext) {
    this.storeContext = storeContext;
    this.stateIndicator = -1;
    this.specifier = {};
    this.namespace = '/chat';
    this.lastFilterRun = new Date(0);
    this.filterInterval = 5 * 60000;

    this.client = new RealtimeClient(this.namespace, this, this.getBackend.bind(this));
    this.refreshCounter();
    this.interval = window.setInterval(this.takeIntervalActions.bind(this), 60 * 1000);
  }

  getBackend()
  {
    return this.storeContext.rootState.backend;
  }

  getItems()
  {
    return this.storeContext.getters['items'];
  }

  beforeDestroy()
  {
    window.clearInterval(this.interval);
  }

  filter(items)
  {
    return items;
  }

  prepend(oldItems, newItems)
  {
    let result = oldItems;
    for (let element of newItems)
      result.unshift(element);
    return result;
  }

  getStandardSpecifier()
  {
    return this.specifier;
  }

  filterOldItems(oldestID)
  {
    this.lastFilterRun = Date.now();
    const items = this.getItems();
    if (items.length > 0 && (oldestID == -1 || items[items.length-1].id < oldestID))
      this.storeContext.commit('setItems', items.filter(item => item.id >= oldestID && oldestID > -1));
  }

  updateAll(specifier, stateIndicator, content)
  {
    this.stateIndicator = stateIndicator;
    this.storeContext.commit('setItems', this.filter(content.reverse()));

    //console.log('current indicator: ' + this.stateIndicator)
  }

  merge(specifier, previousIndicator, currentIndicator, content)
  {
    //console.log(content);
    //console.log('current indicator: ' + currentIndicator);
    if (previousIndicator== this.stateIndicator)
    {
      //console.log('oldest id (merge): ' + content.oldestID);
      this.filterOldItems(content.oldestID);
      this.storeContext.commit('setItems', this.filter(this.prepend(this.getItems(), content.newItems)));
      this.stateIndicator = currentIndicator;
      //console.log('previous indicator: ' + previousIndicator);
      //console.log('current indicator: ' + currentIndicator);
      //console.log('this.stateIndicator: ' + this.stateIndicator);
    }
    else {
      console.log('not merged!');
      return {merged: false, stateIndicator: this.stateIndicator};
    }

    return {merged: true};
  }

  handleError(e)
  {
    console.log(e);
  }

  async postEntry(author, msg)
  {
    const backend = this.getBackend();
    return await backend.post(process.env.VUE_APP_BACKEND_URL + this.namespace + '/postEntry', {author : author, message: msg});
  }

  async blockUser(trackingID)
  {
    const backend = this.getBackend();
    return await backend.post(process.env.VUE_APP_BACKEND_URL + this.namespace + '/blockUser', {trackingID : trackingID});
  }

  async getOldestID()
  {
    const backend = this.getBackend();
    return await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/getOldestID');
  }

  async ajaxFilterItems() 
  {
    if (this.lastFilterRun > Date.now() - this.filterInterval)
    {
      //console.log('cancelled filter');
      return;
    }
 
    //console.log('execute filter');
    const result = await this.getOldestID();
    if (result.status == 'ok')
    {
      const oldestID = result.data.id;
      this.filterOldItems(oldestID);
    }
  }

  async refreshCounter() 
  {
    if (this.storeContext.rootState.currentRouteName == "chat")
    {
      //console.log(new Date(Date.now()));
      //console.log('refreshCounter');
      const backend = this.getBackend();
      backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/refreshCounter');
    }
  }

  async takeIntervalActions()
  {
    this.ajaxFilterItems();
    this.refreshCounter();
  }
}