export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {
           "Credits" : "Tribut",
         },
         ENG : {
         },
         FRA : {
           "Credits" : "Hommage",
         },
         ITA : {
           "Credits" : "Omaggio",
         },
         CZE : {
           "Credits" : "Uznání",
         },
         POL : {
           "Credits" : "Uznanie",
         },
      }
    }
  },
}