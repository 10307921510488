<template>
  <div>
    <div class="buttonContainer">
      <b-button v-b-toggle.comparison-sector variant="primary"><b-icon icon="bar-chart-fill" variant="warning" /> {{ (!formVisible) ? t('Vergleichen') : t('Vergleich schließen') }}</b-button>
    </div>
    <b-collapse :visible="false" v-model="formVisible" id="comparison-sector">
      <div class="comparisonForm">
        <b-input-group class="mt-3">
          <b-form-select v-model="comparison.heat" :options="heatOptions">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{ translateTableHead('Lauf') }}</b-form-select-option>
            </template>
          </b-form-select>
        
          <b-form-select v-model="comparison.competitor1" :options="competitorOptions">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{ translateTableHead('Fahrer') }} 1</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select v-model="comparison.competitor2" :options="competitorOptions">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{ translateTableHead('Fahrer') }} 2</b-form-select-option>
            </template>
          </b-form-select>
        </b-input-group>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import ClassificationHelper from "@/js-code/classification_helper.js";
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Results.js";
import { timingTranslationMixin } from "@/mixins/translations/live-timing/live-timing.js";

export default {
  mixins: [translationMixin, translationTableMixin, timingTranslationMixin],
  data() {
    return {
      comparison : {
        heat: null,
        competitor1: null,
        competitor2: null
      },
      formVisible : false
    }
  },
  props: ["currentContent", "setComparison"],
  computed: {
    sectorData() {
      if (this.currentContent && this.currentContent.groups)
      {
        return ClassificationHelper.extractSectorData(this.currentContent);
      }
      else
        return [];
    },
    heatOptions() {
      if (this.sectorData.length > 0)
        return this.sectorData[0].heats.map(function (x, index) {
          return {value: index, text: this.translateTableHead(x.name)};
         }, this);
      else
        return [];
    },
    competitorOptions() {
      let result = [];
      for (let entry of this.sectorData)
        result.push({value: entry.ID, text: entry.title});
      return result;
    },
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == 'comparison-sector')
        this.setComparison(isJustShown ? this.comparison : null);
    })
  },
  watch: {
    comparison: {
      handler(val) {
        this.setComparison(this.comparison);
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.comparisonForm {
  display: flex;
  padding: 10px;
}

.buttonContainer {
  text-align: center;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
  padding: 15px;
}
</style>
