var moduleLanguage = {
  namespaced: true,

  state: {
    languages: [
        { img: 'deu.jpg', shortcut: 'DE', id: 'DEU' },
        { img: 'gbr.jpg', shortcut: 'EN', id: 'ENG' },
        { img: 'fra.jpg', shortcut: 'FR', id: 'FRA' },
        { img: 'ita.jpg', shortcut: 'IT', id: 'ITA' },
        { img: 'cze.jpg', shortcut: 'CZ', id: 'CZE' },
        { img: 'pol.jpg', shortcut: 'PL', id: 'POL' },
    ],
    current: null
  },

  getters: {
    languages: state => {
      return state.languages
    },

    current: state => {
      return (state.current) ? state.current : state.languages[0];
    }
  },

  mutations: {
    set(state, value) {
      if (state.current != value)
        state.current = value;
    }
  },

  actions: {
    async init(context) {
      context.commit('set', context.state.languages[0]);
    },
  }
}

export {
  moduleLanguage
}