import ChatClient from "../js-code/chat.js";

var moduleChat = {
  namespaced: true,

  state: {
    author: '',
    items: [
      /*{id: 100, time: new Date(0,0,0,14,30,0), registeredUser: true, userID: 5, name: "Michael Hugo-Franz", msg: "Hallo Welt zum Zweiten!"},
       {id: 99, time: new Date(0,0,0,14,30,0), registeredUser: false, name: "Egon", msg: "Ich bin hier nicht angemeldet!"},
       {id: 98, time: new Date(0,0,0,14,25,0), registeredUser: false, name: "Heinrich Karl-Egon", msg: "Das ist eine mega-lange Nachricht, die gar nicht mehr aufhören will und das Ende der Zeile mühelos erreicht. Da schauen wir mal, was der Chat macht."},
       {id: 97, time: new Date(0,0,0,14,20,0), registeredUser: true, userID: 5, name: "Michael", msg: "Hallo Welt!"},*/
    ],
    client: null,
  },

  getters: {
    client: state => {
      return state.client;
    },

    author: state => {
      return state.author;
    },

    items: state => {
      return state.items;
    }
  },

  mutations: {
    setClient(state, value) {
      state.client = value;
    },

    setAuthor(state, value) {
      state.author = value;
    },

    setItems(state, value) {
      state.items = value;
    }
  },

  actions: {
    async init(context) {
      context.commit('setClient', new ChatClient(context));
    },

    async sendMessage(context, {author, message, onSuccess, onError}) {
      if (context.getters['author'].trim() == '')
        context.commit('setAuthor', author);

      const client = context.getters['client'];

      try {
        const result = await client.postEntry(context.getters['author'].trim(), message);

        if (result.status == 'ok')
        {
          if (result.data.status == 'ok')
          {
            onSuccess();
          }
          else
            onError(result.data.message);
        }
        else
        {
          onError(result.message);
        }
      } catch(e) {
        onError(e);
      }
    },

    async blockUser(context, {trackingID, onError, onSuccess}) {
      const client = context.getters['client'];

      try {
        const result = await client.blockUser(trackingID);
        if (result.status == 'ok')
          {
            if (result.data.status == 'ok')
            {
              onSuccess(result.data.message);
            }
            else
              onError(result.data.message);
          }
          else
          {
            onError(result.message);
          }
      } catch (e) {
        onError(e)
      }
    },
  }
};

export {
  moduleChat
}