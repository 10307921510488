<template>
  <div class="credits">
    <pageTitle>{{ t('Credits') }}</pageTitle>

    <div>
      <ul>
        <li>Die Wetter-Daten werden unter der internationalen <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/">Creative Commons Attribution-ShareAlike 4.0 (CC BY-SA 4.0)</a>-Lizenz von <a target="_blank" href="https://openweathermap.org/">OpenWeatherMap</a> zur Verfügung gestellt.</li>
      </ul>
   </div>
  </div>
</template>

<script>
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Credits.js";

export default {
  mixins: [translationMixin, translationTableMixin],
}
</script>

<style>
</style>