<template>
 <div class="spoonsor">
  <map :name="mapId">
   <area v-for="link in links" :coords="link.coords" :href="link.href" :key="link.coords" shape="poly" target="_blank" onFocus="this.blur()" />
  </map>
  <img :src="img" :width="width" border="0" :useMap="'#' + mapId" />
 </div>
</template>

<script>
export default {
  props: {
    links: Array,
    img: String,
    width: {
      type: Number
    }
  },

  data() {
    return {

      mapId: null
    }
  },

  mounted() {
    this.mapId = this._uid + 'map';
  }
}
</script>

<style scoped>
</style>