import axios from 'axios';

export default class Backend {
  constructor(storeContext) 
  {
    this.storeContext = storeContext;
  }

  async http(method, url, data, params)
  {
    var thisData = {};
    if (data != null)
      thisData = data;

    var thisParams = {};
    if (params != null)
      thisParams = params;

    const currentRace = this.storeContext.rootGetters['race/currentParam'];
    const userApiKey = this.storeContext.rootGetters['userManagement/userKey'];
    thisParams.race = currentRace;
    thisParams.api_key = userApiKey;

    //console.log(url);
    //console.log(thisParams);

    try {
      const temp = await axios.request({
        url: url,
        withCredentials: true,
        method: method,
        params: thisParams,
        data: thisData,
      });
      return {status: 'ok', data: temp.data}
    }
    catch (e)
    {
      console.log(e);
      return {status: 'error', message: e};
    }
  }

  async get(url, params = null)
  {
    return await this.http('get', url, null, params);
  }

  async post (url, data, params = null)
  {
    return await this.http('post', url, data, params);
  }
}