export const translationTableBackendMessages = {
  data() {
    return {
      translation : {
         DEU : {
           // users
           'auth invalid' : 'Zugangsdaten nicht korrekt',
           'not confirmed' : 'Benutzerregistrierung nicht bestätigt',
           'unauthorized' : 'Benutzer nicht angemeldet',
           'unknown user' : 'Benutzer unbekannt',
           'empty token' : 'leerer Schlüssel',
           'invalid token' : 'ungültiger Schlüssel',
           'old password' : 'Altes Password nicht korrekt',
           'e-mail invalid' : 'E-Mail-Adresse ungültig',
           'name empty' : 'Name ist leer',
           'password empty' : 'Passwort ist leer',
           'user already existing' : 'Benutzer existiert bereits',

           // access control
           'access blocked' : 'Zugang verweigert',
           'no access control' : 'Zugriff auf Zugriffskontrolle nicht möglich',

           // administration
           'no admin' : 'keine Admin-Rechte',

           // race management
           'could not create directories' : 'Konnte Verzeichnisse nicht erstellen',
           'race not found' : 'kein aktuelles Rennen gesetzt',
           'race not existing' : 'Rennen existiert nicht',
           'race already exists' : 'Rennen existiert bereits',

           // ticker
           'invalid race' : 'ungültiges Rennen',
         },
         ENG : {
           // users
           'auth invalid' : 'user data not correct',
           'not confirmed' : 'registration not confirmed',
           'unauthorized' : 'user not logged in',
           'unknown user' : 'user unknown',
           'empty token' : 'token is empty',
           'invalid token' : 'token is invalid',
           'old password' : 'old password is not correct',
           'e-mail invalid' : 'e-mail address is invalid',
           'name empty' : 'name is empty',
           'password empty' : 'password is empty',
           'user already existing' : 'user already exists',

           // access control
           'access blocked' : 'access denied',
           'no access control' : 'no access to access control system',

           // administration
           'no admin' : 'no admin rights granted',

           // race management
           'could not create directories' : 'could not create directories',
           'race not found' : 'current race not set',
           'race not existing' : 'race does not exists',
           'race already exists' : 'race already exists',

           // ticker
           'invalid race' : 'race is invalid',
         },
         FRA : {

         },
         ITA : {

         },
         CZE : {

         },
         POL : {

         },
      }
    }
  },
}