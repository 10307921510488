import WeatherClient from "../js-code/weather.js";

var moduleWeather = {
  namespaced: true,

  state : {
    client : null,
    received: false,
    data : {
      weather: {
        id: 0,
        main: "keine Wetter-Info",
        description : "",
        icon: "01d",
      },
      temp: 0,
      wind: {
        speed: 0,
        deg: 90
      }
    }
  },

  getters : {
    data: state => {
      return state.data;
    },

    received : state => {
      return state.received;
    },
  },

  mutations : {
    setClient(state, value) {
      state.client = value;
    },

    setData(state, value) {
      state.data = value;
    },

    setReceived(state, value) {
      state.received = value;
    }
  },

  actions : {
    async init(context) {
      context.commit('setClient', new WeatherClient(context));
    }
  }
};

export {
  moduleWeather
}