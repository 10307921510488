export default class LocalSettingsClient {
  constructor(storeContext) {
    this.storeContext = storeContext;
  }

  async getAll() {
    const backend = this.storeContext.rootState.backend;
    const result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/localsettings/getAll');
    return result.data;
  }
}