import RealtimeClient from "./realtime_node.js";
import DiffMatchPatch from "diff-match-patch";

export default class LiveTimingClient {
  constructor(storeContext) {
    this.storeContext = storeContext;
    this.namespace = '/livetiming'; 

    this.stateIndicator = '';
    this.updatingAll = false;
    this.currentState = [];
    this.race = this.storeContext.rootGetters['race/currentParam'];
    this.specifier = {race : this.race};
    this.diffMatchPatch = new DiffMatchPatch();

    this.client = new RealtimeClient(this.namespace, this, this.getBackend.bind(this));
    window.setInterval(this.updateEventStatus.bind(this), 30000);
  }

  getBackend()
  {
    return this.storeContext.rootState.backend;
  }

  getStandardSpecifier()
  {
    return this.specifier;
  }

  updateAll(specifier, stateIndicator, content)
  {
    //console.log('updateAll');
    //console.log(this.specifier.race);
    //console.log(this.race);
    if (this.specifier.race === this.race)
    {
      this.stateIndicator = stateIndicator;
      this.currentState = content;

      //console.log(this.currentState);

      this.storeContext.commit('setResults', this.currentState);
    }
  }

  merge(specifier, previousIndicator, currentIndicator, content)
  {
    //console.log('merge');
    //console.log(this.specifier.race);
    //console.log(this.race);
    //console.log('try merge (1)');
    if (this.specifier.race === this.race)
    {
      //console.log('try merge (2)');
      //console.log('previous indicator: ' + previousIndicator);
      //console.log('my indicator: ' + this.stateIndicator);
      //console.log('new indicator: ' + currentIndicator);
      if (previousIndicator === this.stateIndicator)
      {
        //console.log('try merge (3)');
        if (content.increments.length !== this.currentState.results.length && content.increments.length !== 0)
          return {merged: false, stateIndicator: this.stateIndicator};


        //console.log('try merge (4)');
        // if no increments are transferred, maybe the event state is delivered on its own
        if (content.increments.length !== 0)
        {
          let patchedContent = [];
          for (let x=0; x<content.increments.length; x++)
          {
            if (content.increments[x].Name !== this.currentState.results[x].Name)
              return {merged: false, stateIndicator: this.stateIndicator};

            //console.log(content.increments[x].Patches);
            //const oldContent = this.currentState.results[x].Content;

            if (content.increments[x].Patches.length > 0)
            {
              const patchResult =  this.diffMatchPatch.patch_apply(content.increments[x].Patches, this.currentState.results[x].Content);
              patchedContent.push(patchResult[0]);
            }
            else
              patchedContent.push(this.currentState.results[x].Content);

            //if (content.increments[x].Patches.length > 0)
            //{
            //  console.log(content.increments[x].Name);
            //  console.log(oldContent);
            //  console.log(patchedContent[x]);
            //  JSON.parse(patchedContent[x]);
            //}
          }

          this.currentState.eventState = content.eventState;

          for (let x=0; x<content.increments.length; x++)
            this.currentState.results[x].Content = patchedContent[x];
          //console.log('merged!');
        }

        this.storeContext.commit('setResults', this.currentState);
        this.stateIndicator = currentIndicator;
      }
      else {
        //console.log('merge failed!');
        return {merged: false, stateIndicator: this.stateIndicator};
      }
    }

    return {merged: true};
  }

  async updateEventStatus() {
    const backend = this.storeContext.rootState.backend;

    const result = await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/getEventState');

    if (result.status === 'ok' && result.data.status === 'ok')
      this.storeContext.commit('setEventState', result.data.data);
    else
      this.storeContext.commit('setEventState', 'internet');
  }

  async updateAllResults() {
    //console.log('updateAllResults');
    if (!this.updatingAll) {
      this.updatingAll = true;
      try {
        const result = this.client.getAll(this.specifier);
  
        this.currentState = result.content;
    
        this.storeContext.commit('setResults', this.currentState);
        this.stateIndicator = result.stateIndicator;
      }
      finally {
        this.updatingAll = false;
      }
    }
  }

  async updateResults()
  {
    const backend = this.storeContext.rootState.backend;

    return await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/updateResults');
  }

  async updateCache()
  {
    const backend = this.storeContext.rootState.backend;

    return await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/updateCache');
  }

  async restartClassification()
  {
    const backend = this.storeContext.rootState.backend;

    return await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/restartClassification');
  }

  async checkCurrentEvents()
  {
    const backend = this.storeContext.rootState.backend;

    return await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/checkCurrentEvents');
  }

  handleError(e)
  {
    console.log(e);
  }
}