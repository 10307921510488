import Vue from "vue";
import VueMeta from "vue-meta";
import VueDump from "vue-dump";
import moment from 'moment';
import { DataDump } from "vue-dump";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import VueBootstrapToasts from "vue-bootstrap-toasts";
import LPNotifications from "@/plugins/NotificationPlugin.js";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPhone, faMobile, faEnvelope, faPuzzlePiece, faLanguage, faCloudSunRain, faFileCode
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faPhone, faMobile, faEnvelope, faPuzzlePiece, faLanguage, faCloudSunRain, faFileCode
)

// global components
import PageTitle from "@/components/textblocks/PageTitle.vue";
import BlockTitle from "@/components/textblocks/BlockTitle.vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.filter('formatTime', function (value, formatString) {
  if (value)
    return moment(String(value)).format(formatString);
  else
    return '';
});

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(VueDump);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueBootstrapToasts);
Vue.use(LPNotifications, { store });

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('dump', DataDump);
Vue.component('pageTitle', PageTitle);
Vue.component('blockTitle', BlockTitle);

new Vue({
  router,
  store,
  watch: {
    '$route.params.race' (value) {
       if (value !== undefined)
         this.$store.commit('race/set', value);
    },
    $route(to) {
      this.$store.commit('setCurrentRouteName', to.name);
    }
  },
  created: async function () {
    var race = this.$route.params.race;
    if (race !== undefined)
      this.$store.commit('race/set', race);
    this.$store.commit('setCurrentRouteName', this.$route.name);
    await this.$store.dispatch('init');
  },
  render: h => h(App)
}).$mount("#app");