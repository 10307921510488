<template>
 <div class="overview">
  <blockTitle>{{ t('Übersicht') }}</blockTitle>
  <Arrived />
  <OnTrack />
  <Starting />
 </div>
</template>

<script>
import Starting from "@/components/overview/Starting.vue";
import OnTrack from "@/components/overview/OnTrack.vue";
import Arrived from "@/components/overview/Arrived.vue";

import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/Overview.js";

export default {
  mixins: [translationMixin, translationTableMixin],
  components: {
    Starting,
    OnTrack,
    Arrived
  }
}
</script>

<style scoped>
.overview {
  margin-top: 5px;
  width: 100%;
}
</style>