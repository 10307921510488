<template>
 <div class="register">
  <pageTitle>{{ t('Registrierung') }}</pageTitle>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-email"
        :label="textMail + ':'"
        label-for="input-email"
        :description="textDescriptionMail+'.'"
      >
        <b-form-input
          id="input-email"
          v-model="form.email"
          type="email"
          required
          :placeholder="textPlaceholderMail"
        ></b-form-input>
      </b-form-group>

      <b-form-group 
        id="input-group-username" 
        :label="textUsername + ':'"
        label-for="input-username"
        :description="textDescriptionUsername+'.'">
        <b-form-input
          id="input-username"
          v-model="form.name"
          required
          :state="validationName"
          :placeholder="textPlaceholderUsername"
        ></b-form-input>
        <b-form-invalid-feedback :state="validationName">{{ t('Name darf nicht leer sein') }}.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-password"
        :label="textPassword + ':'"
        label-for="input-password"
      >
        <b-form-input
          id="input-password"
          v-model="form.password"
          type="password"
          required
          :state="validationPassword"
          :placeholder="textPlaceholderPassword"
        ></b-form-input>
        <b-form-invalid-feedback :state="validationPassword">{{ t('Passwort darf nicht leer sein') }}.</b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary">{{ t('Registrieren') }}</b-button>
      <b-button type="reset" variant="danger">{{ t('Reset') }}</b-button>
    </b-form>
 </div>
</template>

<script>
import { userDataFormMixin } from "../../mixins/userDataFormMixin.js"
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/User-Management/UserManagement.js";

  export default {
    mixins: [translationMixin, translationTableMixin, userDataFormMixin],
    data() {
      return {
        form: {
          email: '',
          name: '',
          password: ''
        }
      }
    },
    computed: {
      validationName() {
        return this.form.name.length > 0;
      },
      validationPassword() {
        return this.form.password.length > 0;
      },

      textMail() {
        return this.t('E-Mail Adresse');
      },

      textPlaceholderMail() {
        return this.t('Bitte E-Mail Adresse eingeben');
      },

      textDescriptionMail() {
        return this.t('Wird zur Registierung und Anmeldung benötigt');
      },

      textUsername() {
        return this.t('Benutzername');
      },

      textPlaceholderUsername() {
        return this.t('Bitte Benutzernamen eingeben');
      },

      textDescriptionUsername() {
        return this.t('Wird im Chat angezeigt');
      },

      textPassword() {
        return this.t('Passwort');
      },

      textPlaceholderPassword() {
        return this.t('Bitte Passwort eingeben');
      }
    },
    created() {
      this.action = 'register';
      this.successMessage = this.t('successMessageRegister') + '!';
      this.failureMessage = this.t('errorMessageRegister') + ':';
      this.goToPreviousPageOnSuccess = true;
    },
    methods: {
      clearData() {
        this.form.email = '';
        this.form.name = '';
        this.form.password = '';
      },
      submissionOk() {
        return this.validationName && this.validationPassword;
      },
    }
  }
</script>

<style scoped>
</style>