export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {

         },
         ENG : {
           "Warte auf Daten" : "waiting for data",
           "Veranstaltung läuft" : "event active",
           "keine Verbindung" : "live-timing inactive",
           "keine Daten" : "no data",
           "Unterbrechung" : "interruption",
           "Internet-Problem" : "disconnected",
         },
         FRA : {
           "Warte auf Daten" : "attendre les données",
           "Veranstaltung läuft" : "événement actif",
           "keine Verbindung" : "chronométrage en direct inactif",
           "keine Daten" : "pas de données",
           "Unterbrechung" : "interruption",
           "Internet-Problem" : "problème d'internet",
         },
         ITA : {
           "Warte auf Daten" : "aspetta le date",
           "Veranstaltung läuft" : "evento attivo",
           "keine Verbindung" : "timing live inattivo",
           "keine Daten" : "nessun dato",
           "Unterbrechung" : "interruzione",
           "Internet-Problem" : "problema di internet",
         },
         CZE : {
           "Warte auf Daten" : "počkejte na data",
           "Veranstaltung läuft" : "událost aktivní",
           "keine Verbindung" : "živé načasování neaktivní",
           "keine Daten" : "žádná data",
           "Unterbrechung" : "přerušení",
           "Internet-Problem" : "problém s internetem",
         },
         POL : {
           "Warte auf Daten" : "poczekaj na dane",
           "Veranstaltung läuft" : "wydarzenie aktywne",
           "keine Verbindung" : "czas na żywo nieaktywny",
           "keine Daten" : "brak danych",
           "Unterbrechung" : "przerwa",
           "Internet-Problem" : "problem z internetem",
         },
      }
    }
  },
}