import LocalSettingsClient from "../js-code/local_settings.js"

var moduleLocalSettings = {
  namespaced: true,

  state: {
    data: {}
  },

  getters: {
    data: (state) => {
      return state.data;
    }
  },

  mutations: {
    setData(state, value) {
      state.data = value;
    },
  },

  actions: {
    async init(context) {
      const updater = new LocalSettingsClient(context);
      const data = await updater.getAll();
      context.commit('setData', data);
    },
  }
}

export {
  moduleLocalSettings
}