import User from "./user.js"

export default class UserManagement {
  currentUser;
  storeContext;

  constructor(storeContext) {
    this.storeContext = storeContext;
    this.currentUser = new User(storeContext);
  }

  async tryLogin()
  {
    if (this.storeContext && this.storeContext.rootState.allowCookies)
    {
      let apiKey = localStorage.getItem('api-key');
      await this.loginViaAPIKey(apiKey);
    }
  }

  async loginViaAPIKey(apiKey)
  {
    const result = await this.currentUser.loginViaAPIKey(apiKey);

    if (this.storeContext && this.storeContext.rootState.allowCookies && localStorage.getItem('api-key') == apiKey 
        && apiKey != '' && result && result.status == 'ok')
      {
        localStorage.setItem('api-key', this.currentUser.api_key);
      }

      return result;
  }

  async login(email, password, rememberMe) 
  {
    const result = await this.currentUser.login(email, password);

    if (this.storeContext && this.storeContext.rootState.allowCookies && rememberMe && result.status == 'ok')
    {
      localStorage.setItem('api-key', this.currentUser.api_key);
    }

    return result;
  }

  async changePassword(oldPassword, newPassword)
  {
    const oldApiKey = this.currentUser.api_key; 

    const result = await this.currentUser.changePassword(oldPassword, newPassword);

    if (this.storeContext && this.storeContext.rootState.allowCookies && localStorage.getItem('api-key') == oldApiKey && oldApiKey != '' && result.status == 'ok')
    {
      localStorage.setItem('api-key', this.currentUser.api_key);
    }

    return result;
  }

  async changeData(data)
  {
    return await this.currentUser.changeData(data);
  }

  async logout() 
  {
     if (this.storeContext)
       localStorage.removeItem('api-key');

     return await this.currentUser.logout();
  }

  getErrorResult(result) 
  {
    const msgFormatter = this.storeContext.rootState.msgFormatter;
    const message = msgFormatter.format(result.message);
    return {status: 'error', message: message};
  }

  async register(userDefinition)
  {
     console.log('Register');
     console.log(userDefinition);

     let result =  await User.post('/user/register', userDefinition);

     if (result.status == 'ok')
     {
       return {status : 'ok', message : 'Nutzer wurde registriert'}
     }
     else
       return this.getErrorResult(result);
  }


  async requestPasswordReset(data)
  {
     let result = await User.post('/user/requestPasswordReset', {email : data.email});

     if (result.status == 'ok')
     {
       return {status : 'ok', message : 'E-Mail zum Zurücksetzen versendet'}
     }
     else
       return this.getErrorResult(result);
  }

  async resetPassword(data)
  {
     const result = await User.post('/user/resetPassword', {user_id : data.user_id, token : data.token, newPassword : data.newPassword1});
     if (result.status == 'ok')
       return {status : 'ok', message : 'Passwort zurückgesetzt'};
     else
       return this.getErrorResult(result);
  }

  async confirmRegistration(data)
  {
     const result = await User.post('/user/confirmRegistration', {user_id : data.user_id, token : data.token});
     if (result.status == 'ok')
       return {status : 'ok', message : 'Passwort zurückgesetzt'};
     else
       return this.getErrorResult(result);
  }
}