<template>
 <div id="sponsors">
  <span class="title">{{ t('mit freundlicher Unterstützung von') }}:</span>
  <div class="content">
   <div v-for="(sponsor, index) in sponsors" :key="'sponsor' + sponsor.link + index">
    <SponsorStd v-if="sponsor.linkType !== 'map'" class="sponsor" :img="getSponsorImgPath(sponsor)" :link="sponsor.link" :width="getWidth(sponsor.width)" />
    <SponsorMap v-else :img="getSponsorImgPath(sponsor)" class="sponsor" :links="sponsor.links" :width="sponsor.width" />
   </div>
  </div>
 </div>
</template>

<script>
import SponsorStd from "@/components/sponsors/SponsorStd.vue";
import SponsorMap from "@/components/sponsors/SponsorMap.vue";

import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/Sponsors.js";

import Helper from "@/js-code/helper";

const sponsors = [
  {
    img: "knuettel_avon_michelin.jpg",
    linkType: "map",
    links: [
      {
         href: "http://www.reifen-knuettel.de",
         coords: "0,61,183,0,183,61"
      },
      {
         href: "http://www.avon-renndienst.de",
         coords: "0,0,183,0,0,61"
      }
    ]
  },
  {
    img: "osna_logo_2024.jpg",
    link: "https://osnabruecker-bergrennen.de/",
    width: 200
  },
  {
    img: "ps_schneider_2022.jpg",
    link: "http://powerschmiedeschneider.com/",
    width: 300
  }
  /*{
    img: "mickhausen_300.png",
    link: "http://www.bergrennen-mickhausen.com",
    width: 300
  }*/
];

export default {
  mixins: [translationMixin, translationTableMixin],
  components: {
    SponsorStd,
    SponsorMap
  },

  data() {
    return {
      sponsors: sponsors
    }
  },

  methods: {
    getSponsorImgPath: function(sponsor) {
      //var images = require('../assets/sponsors/, false
      return require('../assets/sponsors/' + sponsor.img);
    },

    getWidth: function(originalWidth) {
      if (!Helper.isMobile())
        return originalWidth;
      return Math.floor(originalWidth * 0.6);
    }
  }
}
</script>

<style scoped>
#sponsors {
  background: #edeeff;
  padding-top: 2px;
}

#sponsors .title {
  display: inline-block;
  font-weight: bold;
  font-size: 11pt;
  text-align: center;
  width: 100%;
}

#sponsors .content {
  background: white;
  border: 1px dotted #CBCCDD;
  padding-top: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
}

#sponsors .content .sponsor {
  margin-bottom: 5px;
}
</style>