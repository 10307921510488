export const translationMixin = {
  data() {
    return {
      translation : {
      }
    }
  },
  computed: {
    currentLanguage() {
      return this.$store.getters['language/current'];
    },
  },
  methods: {
    translate(text, map)
    {
      const langId = this.currentLanguage.id;

      if (map[langId] && map[langId][text])
        return map[langId][text];
      if (langId != 'DEU' && map['ENG'] && map['ENG'][text])
        return map['ENG'][text];
      return text;
    },

    t(text) {
      return this.translate(text, this.translation);
    },
  },
}