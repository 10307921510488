<template>
  <div class="top5_main">
    <blockTitle>Top 5</blockTitle>
    <div class="overviewcomponent top5">
      <OverviewTable :content="currentContent" />
    </div>
  </div>
</template>

<script>
import OverviewTable from "@/components/OverviewTable.vue";

export default {
  components: {
    OverviewTable
  },
  data() {
    return {
    }
  },
  computed: {
    currentContent() {
      const result = JSON.parse(this.$store.getters['liveTiming/results']('Top5'));
      let table = null;
      if (result && result.groups.length > 0 && result.groups[0].subGroups.length > 0)
        table = result.groups[0].subGroups[0].table;
      return table;
    }
  }
}
</script>

<style scoped>
@import "../assets/overview/overview.css";

.top5_main {
  margin-top: 5px;
  width: 100%;
}

.top5 {
  background-image: url(../assets/top5_bg_small.png);
}
</style>