export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {

         },
         ENG : {
           "Einen Ticker-Eintrag erstellen" : "Create an Entry for our Ticker",
           "Nachricht darf nicht leer sein" : "Message must not be empty",
           "Info schicken" : "Send information",
           "Reset" : "Reset",
           "Nachricht" : "Message",
           "Bitte Nachricht eingeben" : "Please enter your message",
           "Nachricht wurde gepostet" : "Message has been posted"
         },
         FRA : {
           "Einen Ticker-Eintrag erstellen" : "Créer une Entrée de Ticker",
           "Nachricht darf nicht leer sein" : "Le message ne peut pas être vide",
           "Info schicken" : "Envoyer des informations",
           "Reset" : "Remettre",
           "Nachricht" : "Message",
           "Bitte Nachricht eingeben" : "Veuillez saisir un message",
           "Nachricht wurde gepostet" : "Le message a été publié"
         },
         ITA : {
           "Einen Ticker-Eintrag erstellen" : "Crea una Voce nel Ticker",
           "Nachricht darf nicht leer sein" : "Il messaggio non può essere vuoto",
           "Info schicken" : "Invia info",
           "Reset" : "Reset",
           "Nachricht" : "Messaggio",
           "Bitte Nachricht eingeben" : "Inserisci un messaggio",
           "Nachricht wurde gepostet" : "Il messaggio è stato pubblicato"
         },
         CZE : {
           "Einen Ticker-Eintrag erstellen" : "Vytvoření Záznamu v Tickeru",
           "Nachricht darf nicht leer sein" : "Zpráva nemůže být prázdná",
           "Info schicken" : "Odeslat informace",
           "Reset" : "Resetovat",
           "Nachricht" : "Zpráva",
           "Bitte Nachricht eingeben" : "Zadejte prosím zprávu",
           "Nachricht wurde gepostet" : "Zpráva byla zveřejněna"
         },
         POL : {
           "Einen Ticker-Eintrag erstellen" : "Utworzyć Pozycję w Tikerze",
           "Nachricht darf nicht leer sein" : "Wiadomość nie może być pusta",
           "Info schicken" : "Wyślij informacje",
           "Reset" : "Zresetować",
           "Nachricht" : "Wiadomość",
           "Bitte Nachricht eingeben" : "Proszę wpisać wiadomość",
           "Nachricht wurde gepostet" : "Wiadomość została opublikowana"
         },
      }
    }
  },
}