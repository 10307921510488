<template>
 <div class="register">
  <pageTitle>{{ t('Anmeldung') }}</pageTitle>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-email-group"
        :label="textMail + ':'"
        label-for="input-email"
      >
        <b-form-input
          id="input-email"
          v-model="form.email"
          type="email"
          required
          :placeholder="textPlaceholderMail"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-password"
        :label="textPassword + ':'"
        label-for="input-password"
      >
        <b-form-input
          id="input-password"
          v-model="form.password"
          type="password"
          required
          :placeholder="textPlaceholderPassword"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-rememberMe">
       <b-form-checkbox id="input-rememberMe" v-model="form.rememberMe" :disabled="!cookiesAllowed">{{ t('angemeldet bleiben') }}</b-form-checkbox>
      </b-form-group>

      <b-button type="submit" variant="primary">{{ t('Anmelden') }}</b-button>
      <b-button type="reset" variant="danger">{{ t('Reset') }}</b-button>

      <div class="resetPassword"><router-link to="/requestPasswordReset">{{ t('Passwort vergessen') }}?</router-link></div>
    </b-form>
 </div>
</template>

<script>
import { userDataFormMixin } from "../../mixins/userDataFormMixin.js"
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/User-Management/UserManagement.js";

  export default {
    mixins: [translationMixin, translationTableMixin, userDataFormMixin],
    data() {
      return {
        form: {
          email: '',
          password: '',
          rememberMe: false
        }
      }
    },
    created() {
      this.action = 'login';
      this.successMessage = this.t('successMessageLogin') + '!';
      this.failureMessage = this.t('errorMessageLogin') + ':';
      this.goToPreviousPageOnSuccess = true;
    },
    computed: {
      cookiesAllowed() {
        return this.$store.getters.cookiesAllowed;
      },

      textMail() {
        return this.t('E-Mail Adresse');
      },

      textPlaceholderMail() {
        return this.t('Bitte E-Mail Adresse eingeben');
      },

      textPassword() {
        return this.t('Passwort');
      },

      textPlaceholderPassword() {
        return this.t('Bitte Passwort eingeben');
      }
    },
    methods: {
      clearData() {
        this.form.email = ''
        this.form.password = ''
        this.form.rememberMe = false
      },
      submissionOk() {
        return true;
      }
    }
  }
</script>

<style scoped>
.resetPassword {
  margin-bottom: 5px;
  font-size: small;
}
</style>