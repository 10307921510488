<template>
  <div class="confirmRegistration">
    <pageTitle>{{ t('Registrierung bestätigen') }}</pageTitle>
    <div :class="['message', messageClass]"> 
     {{ confirmationMessage }}
    </div>
  </div>
</template>

<script>
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/User-Management/UserManagement.js";

export default {
  mixins: [translationMixin, translationTableMixin],
  data() {
    return {
      data : {
        token: '',
        user_id: ''
      },
      messageClass: '',
      confirmationMessage : 'Registrierung wird überprüft',
      confirmationTried : false,
    }
  },
  methods: {
    onActionAttempted(result) {
      if (result.status === 'ok')
      {
        this.confirmationMessage = this.t('successRegistrationConfirmation') + "!";
      }
      else
      {
        this.messageClass = 'errorState';
        this.confirmationMessage = this.t('errorRegistrationConfirmation') + ":\n" + result.message;
      }
    },
    async confirmRegistration() {
      await this.$store.dispatch('userManagement/confirmRegistration', {
          data : this.data,
          onActionAttempted : this.onActionAttempted.bind(this)
        });
    }
  },
  created() {
    this.data.token = this.$route.query.token;
    this.data.user_id = this.$route.query.user_id;
  },
  async mounted() {
    let interval = null;
    interval = setInterval(function() {
      if (!this.confirmationTried && this.$store.getters['userManagement/isInitialized']) {
        this.confirmRegistration();
        this.confirmationTried = true;
      }

      if (this.confirmationTried && interval)
        clearInterval(interval);
    }.bind(this), 500);
  }
}
</script>

<style scoped>
.message {
  display: block;
  text-align: center;
  width: 100%;
}

.errorState {
  color: red;
}
</style>