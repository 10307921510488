import Vue from "vue";
import Vuex from "vuex";

import Backend from "../js-code/backend.js";
import MessageFormatter from "../js-code/message_formatting.js";

import { moduleRace } from "./module_race.js";
import { moduleUser } from "./module_user.js";
import { moduleStatistics } from "./module_statistics.js";
import { moduleLiveTiming } from "./module_livetiming.js";
import { moduleLanguage } from "./module_language.js";
import { moduleChat } from "./module_chat.js";
import { moduleTicker } from "./module_ticker.js";
import { moduleWeather } from "./module_weather.js";
import { moduleDownloads } from "./module_downloads.js";
import { moduleLocalSettings } from "./module_localsettings.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    allowCookies : true,
    backend: null,
    msgFormatter: null,
    currentRouteName: ''
  },

  getters: {
    cookiesAllowed: state => {
      return state.allowCookies;
    },

    formatError: state => value => {
      if (state.msgFormatter != null)
        return state.msgFormatter.format(value);
      else
        return 'Fehler-Formatierung fehlgeschlagen';
    },
  },

  mutations: {
    setMsgFormatter(state, value) {
      state.msgFormatter = value;
    },

    setBackend(state, value) {
      state.backend = value;
    },

    setCurrentRouteName(state, value) {
      state.currentRouteName = value;
    }
  },

  actions: {
    async init(context) {
      context.commit('setMsgFormatter', new MessageFormatter(context));
      context.commit('setBackend', new Backend(context));

      await context.dispatch('language/init');
      await context.dispatch('userManagement/init');

      // from here, all HTTP-requests can build upon userManagement and current race parameter
      await context.dispatch('race/init');

      // no await here, since these modules can be initialized independently
      context.dispatch('statistics/init');
      context.dispatch('liveTiming/init');
      context.dispatch('chat/init');
      context.dispatch('ticker/init');
      context.dispatch('weather/init');
      context.dispatch('downloads/init');
      context.dispatch('localSettings/init');
    },
  },

  modules: {
    race: moduleRace,
    userManagement: moduleUser,
    statistics: moduleStatistics,
    liveTiming: moduleLiveTiming,
    language: moduleLanguage,
    chat : moduleChat, 
    ticker : moduleTicker,
    weather : moduleWeather,
    downloads : moduleDownloads,
    localSettings: moduleLocalSettings
  }
});

export default store;
