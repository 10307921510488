export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {

         },
         ENG : {
           'Starterliste' : 'List of Competitors',
           'alle' : 'all',
         },
         FRA : {
           'Starterliste' : 'Liste des Concurrents',
           'alle' : 'tous/toutes'
         },
         ITA : {
           'Starterliste' : 'Elenco dei Concorrenti',
           'alle' : 'tutti/tutte'
         },
         CZE : {
           'Starterliste' : 'Seznam Závodníků',
           'alle' : 'všechny'
         },
         POL : {
           'Starterliste' : 'Lista Konkurentów',
           'alle' : 'wszystkie'
         },
      }
    }
  },
}