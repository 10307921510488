<template>
  <div>
    <b-form-select v-model="selected" :options="list"></b-form-select>
    <b-table v-if="current" :items="current">
      <template #cell(log)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(lastupdate)="data">
        {{ data.value | formatTime('DD.MM.YYYY') }}<br>{{ data.value | formatTime('HH:mm:ss.SSS') }}
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      log: null,
      selected: null
    }
  },
  computed: {
    list() {
      if (!this.log)
        return [];
      return this.log.map(x => { return { value: x.Name, text: x.Name + ' (' + x.ComputationTime + ')'}});
    },
    current() {
      if (!this.selected)
        return [];
      let row = JSON.parse(JSON.stringify(this.log.find(element => element.Name === this.selected)));

      row.Log = row.Log.replaceAll("\n", "<br>\n");
      return [row];
    }
  },
  methods: {
    async getResultLog() {
      const backend = this.$store.state.backend;
      const result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/liveTiming/getResultLog');
      if (result.status === 'ok') {
        if (result.data.status === 'ok')
        {
          this.log = result.data.data;
        }
      }
    }
  },
  mounted() {
    this.getResultLog();
  }
}
</script>