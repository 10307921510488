<template>
  <span>
    <span v-if="content" :class="content.htmlClass">
      <span v-if="content.units"><LTUnit v-for="(u, index) in content.units" :key="'BU' + index" :translator="translator" :unit="u" :parentTblStacked="parentTblStacked" /></span>
      <span v-else-if="content.text !== undefined" :class="(content.text === '*') ? 'contains_only_star' : ''">{{ (translator) ? translator(content.text) : content.text }}</span>
    </span>
  </span>
</template>

<script>
//import LTUnit from "@/components/livetiming/LTUnit.vue";

export default {
  components: {
    LTUnit: () => import("@/components/livetiming/LTUnit.vue")
  },
  props: {
    "content" : {},
    "translator" : {
       default: null
     },
    "parentTblStacked" : {
      default: false
    },
  }
}
</script>

<style>
.group_title {
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
</style>