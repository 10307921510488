import Race from "../js-code/race.js"

var moduleRace = {
  namespaced: true, 

  state: {
    race: new Race(),
  },

  getters: {
    resolve: state => {
      return state.race.resolve();
    },

    currentParam: state => {
      return state.race.asParameter();
    },
  },

  mutations: {
    set(state, value) {
      if (state.race.race != value)
        state.race.race = value;
    },
  },

  actions: {
    async init(context) {
      context.state.race.setStoreContext(context);
      await context.state.race.determineCurrent();
    },

    async refresh(context) {
      await context.state.race.determineCurrent();
    },
  },
}

export {
  moduleRace
}