export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {

         },
         ENG : {
           "Übersicht" : "Overview"
         },
         FRA : {
           "Übersicht" : "Vue Globale"
         },
         ITA : {
           "Übersicht" : "Panoramica"
         },
         CZE : {
           "Übersicht" : "Prehled"
         },
         POL : {
           "Übersicht" : "Przegląd"
         },
      }
    }
  },
}