import TickerClient from "../js-code/ticker.js";

var moduleTicker = {
  namespaced: true,

  state : {
    items: [],
    maxItems: 12,
    client: null,
  },

  getters: {
    client: state => {
      return state.client;
    },

    maxItems: state => {
      return state.maxItems;
    },

    items: state => {
      return state.items;
    },
  },

  mutations: {
    setClient(state, value) {
      state.client = value;
    },

    setItems(state, value) {
      state.items = value;
    }
  },

  actions: {
    async init(context) {
      context.commit('setClient', new TickerClient(context));
    },

    async testUpdate(context) {
      const value = await context.state.client.testUpdate();
      alert(JSON.stringify(value)); 
    }
  }
};

export {
  moduleTicker
}