var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about main_bg"},[_c('pageTitle',[_vm._v(_vm._s(_vm.t('Impressum')))]),_c('div',{staticClass:"block"},[_c('div',{attrs:{"id":"container"}},[_c('div',{attrs:{"id":"address"}},[_c('table',[_vm._m(0),_c('td',[_vm._m(1),_vm._v(" "+_vm._s(_vm.t('Vertretungsberechtigte'))+":"),_c('br'),_vm._v(" Judith, Thomas "+_vm._s(_vm.t('und'))+" Michael Stoll ")])])]),_c('div',{attrs:{"id":"contact"}},[_c('span',{},[_c('br'),_c('table',{staticStyle:{"margin-left":"10px"}},[_vm._m(2),_vm._m(3),_vm._m(4),_c('tr',[_c('td',{staticStyle:{"width":"30px"}},[_c('font-awesome-icon',{attrs:{"icon":"phone"}})],1),_c('td',[_vm._v("(06851) 8 20 79")])]),_c('tr',[_c('td',[_c('font-awesome-icon',{attrs:{"icon":"mobile"}})],1),_c('td',[_vm._v("(0172) 6 87 17 21")])]),_c('tr',[_c('td',[_c('font-awesome-icon',{attrs:{"icon":"envelope"}})],1),_c('td',[_vm._v("thomas(-at-)berg-zeitnahme.de")])])]),_c('hr'),_vm._m(5)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"vertical-align":"middle","padding-left":"10px","padding-right":"10px"}},[_c('img',{attrs:{"src":require("../assets/bzn_logo.png"),"height":"50"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("EDV- und Zeitnahmeservice Stoll GbR")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Königsberger Str. 23")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("66606 "),_c('u',[_vm._v("St. Wendel")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"height":"10px"},attrs:{"colspan":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticStyle:{"margin-left":"10px"}},[_c('tr',[_c('td',{staticStyle:{"padding-right":"10px"}},[_vm._v("USt.-IdNr: ")]),_c('td',[_vm._v("DE288167955")])]),_c('tr',[_c('td',[_vm._v("SteuerNr.: ")]),_c('td',[_vm._v("040/165/02753")])])])
}]

export { render, staticRenderFns }