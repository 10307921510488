<template>
  <div class="chat">
    <pageTitle>{{ t('Chat') }}</pageTitle>

    <div class="chat_input">
      <b-form ref="chat_form" @submit="onSubmit">
        <div class="container">
        <div class="row">
          <div class="col-3" v-show="!authorGiven">
            <b-form-input
              id="input-name"
              v-model="form.name"
              size="sm"
              :placeholder="t('Name')"
            ></b-form-input>
          </div>
          <div class="col">
            <b-form-input
             id="input-message"
             v-model="form.message"
             size="sm"
             required
             :placeholder="messagePlaceholder"
            ></b-form-input>
          </div>
          <div class="col-2" v-show="displaySubmitBtn">
            <b-button type="submit" size="sm" variant="info"><b-icon icon="play-fill" /></b-button> <!-- {{ t('Senden') }} -->
          </div>
        </div>
        </div>
      </b-form>
    </div>

    <div id="chat">
     <div class="messageContainer">
      <div v-for="(item) in items" :key="item.id" class="messageItem">
       <div class="blockUser" v-if="userIsAdmin">
        <span id="blockButton" @click="blockUser(item.trackingID)">X</span>
       </div>
       <div class="time">
        {{ item.time | formatTime('HH:mm') }}
       </div>
       <div class="userInfo">
        <div class="registeredUser" v-show="item.registeredUser">
         <div style="display: table">
           <span class="userID"><span>{{ item.userID }}</span></span>
           <span class="userName"><span>{{ item.name }}</span></span>
         </div>
        </div>
        <div class="normalUser" v-show="!item.registeredUser">
         <span class="userName">{{ item.name }}</span>
        </div>
       </div>
       <div class="message">
        {{ item.msg }}
       </div>
      </div>
     </div>
    </div>

  </div>
</template>

<script>
import { mobileDetectionMixin } from "@/mixins/mobileDetectionMixin.js";
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Chat.js";

export default {
  mixins: [mobileDetectionMixin, translationMixin, translationTableMixin],
  name: 'chat',
  data() {
    return {
      form: {
        name: '',
        message: ''
      },
    }
  },
  methods: {
    onError(message) {
      this.$notifications.error(message);
    },

    onSuccess() {
      this.form.message = '';
    },

    onBlockSuccess(message) {
      this.$notifications.success(message);
    },

    onSubmit(evt) {
      evt.preventDefault();
      if (this.validation)
      {
        this.$store.dispatch('chat/sendMessage', {
          author : this.form.name,
          message : this.form.message,
          onSuccess : this.onSuccess.bind(this),
          onError : this.onError.bind(this),
        });
      }
      else
        this.onError(this.t('errorMessageChatSend') + '!');
    },

    blockUser(trackingID) {
      this.$store.dispatch('chat/blockUser', {
        trackingID : trackingID,
        onSuccess : this.onBlockSuccess.bind(this),
        onError : this.onError.bind(this),
      });
    },
  },
  computed: {
    items() {
      return this.$store.getters['chat/items'];
    },

    chosenAuthorName() {
      return this.$store.getters['chat/author'];
    },
    effectiveAuthorName() {
      return (this.authorLoggedIn) ? this.$store.getters['userManagement/userName'] : this.chosenAuthorName;
    },
    authorLoggedIn() {
      return this.$store.getters['userManagement/userLoggedIn'];
    },
    authorGiven() {
      return this.authorLoggedIn || this.chosenAuthorName != '';
    },
    validation() {
      return (this.authorGiven || this.form.name.trim() != '') && this.form.message.trim() != '';
    },
    messagePlaceholder() {
      if (this.effectiveAuthorName == '') 
        return this.t('Bitte Nachricht eingeben');
      else
        return this.t('Bitte Nachricht eingeben') + ' (' + this.t('als') + ' "' + this.effectiveAuthorName + '")';
    },
    userIsAdmin() {
      return this.$store.getters['userManagement/userIsAdmin'];
    },
    displaySubmitBtn() {
      return this.isMobile() || this.isTablet();
    }
  },
}
</script>

<style scoped>
#input-name {
}

#input-message {
}

#chat {
  background-image: url(../assets/bg.png);
  background-attachment: fixed;
  background-repeat: repeat-x;
  background-color: #b4c9ce;
  width: 100%;
  min-height: 500px;
  max-height: 800px;
  border: 2px solid navy;
  overflow: auto;
}

.messageContainer {
  display: table;
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
}

.messageItem {
  display: table-row;
  width: 100%;
  vertical-align: middle;
}

.blockUser {
  display: table-cell;
  width: 15px;
  vertical-align: top;
  padding: 4px;
  color: red;
  /* background: red; */
  font-weight: bold;
  font-size: 10pt;
  cursor: pointer;
}

.time {
  display: table-cell;
  width: 38px;
  font-size: 8pt;
  vertical-align: top;
  padding-top: 6px;
  padding-left: 3px; 
}

.userInfo {
  display: table-cell;
  /*min-width: 120px;
  max-width: 60px;*/
  width: 150px;
}

.userName {
  font-size: 11pt;
}

.registeredUser {
  display: table-cell;
  margin-left: 1px;
  margin-top: 1px;
  margin-right: 1px;
  padding-left: 5px;
  padding-right: 5px;
  #background: silver;
}

.registeredUser .userID {
  display: table-cell;
  vertical-align: top;
}

.registeredUser .userID span {
  display: inline-block;
  min-width: 30px;
  text-align: center;
  background: white;
  font-size: 10pt;
  font-weight: bold;
}

.registeredUser .userName {
  display: table-cell;
  vertical-align: top;
  padding-top: 3px;
}

.registeredUser .userName span {
  display: inline-block;
  margin-left: 5px;
  font-weight: bold; 
}

.normalUser {
  display: table-cell;
  vertical-align: top;
  padding-left: 35px;
}

.normalUser .userName {
  display: inline-block;
  margin-left: 5px;
  padding-top: 3px;
}

.message {
  display: table-cell;
  vertical-align: top;
  padding-top: 3px;
  font-size: 11pt;
}
</style>