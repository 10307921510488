<template>
  <div class="downloads main_bg">
    <pageTitle>Downloads</pageTitle>
    <div class="hint">
<b>{{ t('Hinweis') }}:</b><br>
{{ t('HinweisText') }}
    </div>

    <!--<b-button @click="test">Test</b-button>-->

    <div class="list_container">
      <div class="downloads_list">
        <span class="list_title">{{ t('Dateien') }} ({{ items.length }})</span>
        <ul>
          <li v-for="(item, index) in items" :key="'i' + index"><a target="_blank" :href="baseLink + '/download?race=' + currentRace + '&filename=' + item">{{ item }}</a></li> 
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/Downloads.js";

export default {
  mixins: [translationMixin, translationTableMixin],
  name: 'downloads',
  computed: {
    items() {
      return this.$store.getters['downloads/items'];
    },

    currentRace() {
      return this.$store.getters['race/currentParam'];
    },

    baseLink() {
      return process.env.VUE_APP_DOWNLOAD_BASE + '/downloads'; 
    }
  },
}
</script>

<style scoped>
div.list_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.downloads_list {
  width: 80%;
  margin-top: 20px;
  background: #edeeff;
  border: 1px dotted #CBCCDD;
}

span.list_title {
  display: inline-block;
  background: #9a99bb;
  color: white;
  font-weight: bold;
  width: 100%;
  text-align:center;
  margin-bottom: 10px;
}
</style>