<template>
 <div class="sponsor">
  <a :href="link" onFocus="this.blur()" target="_blank"><img :src="img" :width="width" border="0" /></a>
 </div>
</template>

<script>
export default {
  props: {
    link: String,
    img: String,
    width: {
      type: [Number, String]
    }
  }
}
</script>

<style scoped>
</style>