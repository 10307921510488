export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {
           Clouds : "wolkig",
           Thunderstorm : "Gewitter",
           Rain : "Regen",
           Snow : "Schneefall",
           Drizzle : "Sprühregen",
           Clear : "klar",
           Mist : "feuchter Dunst",
           Smoke : "Rauch",
           Haze : "trockener Dunst",
           Dust : "staubig",
           Fog : "Nebel",
           Sand : "Sand",
           Ash : "Asche",
           Squall : "böig",
           Tornado : "Tornado",
           "Temperatur" : "Temperatur",
           "Wind" : "Wind",
           "WindDir-N" : "N",
           "WindDir-NE" : "NO",
           "WindDir-E" : "O",
           "WindDir-SE" : "SO",
           "WindDir-S" : "S",
           "WindDir-SW" : "SW",
           "WindDir-W" : "W",
           "WindDir-NW" : "NW",
           "WindDir-U" : " "
         },
         ENG : {
           "Temperatur" : "temperature",
           "Wind" : "wind",
           "WindDir-N" : "N",
           "WindDir-NE" : "NE",
           "WindDir-E" : "E",
           "WindDir-SE" : "SE",
           "WindDir-S" : "S",
           "WindDir-SW" : "SW",
           "WindDir-W" : "W",
           "WindDir-NW" : "NW",
           "WindDir-U" : " "
         },
         FRA : {
           Clouds : "nuageux",
           Thunderstorm : "Orage",
           Rain : "Pluie",
           Snow : "Neige",
           Drizzle : "Bruine",
           Clear : "Clair",
           Mist : "Buée",
           Smoke : "Fumée",
           Haze : "Brume",
           Dust : "Poussière",
           Fog : "Brouillard",
           Sand : "Sable",
           Ash : "Cendre",
           Squall : "Bourrasque",
           Tornado : "Tornade",
           "Temperatur" : "température",
           "Wind" : "vent",
           "WindDir-N" : "N",
           "WindDir-NE" : "NE",
           "WindDir-E" : "E",
           "WindDir-SE" : "SE",
           "WindDir-S" : "S",
           "WindDir-SW" : "SO",
           "WindDir-W" : "O",
           "WindDir-NW" : "NO",
           "WindDir-U" : ""
         },
         ITA : {
           Clouds : "nuvoloso",
           Thunderstorm : "Temporale",
           Rain : "Pioggia",
           Snow : "Neve",
           Drizzle : "Pioggerella",
           Clear : "Tempo Sereno",
           Mist : "Foschia",
           Smoke : "Fumo",
           Haze : "Nebbiolina",
           Dust : "Polvere",
           Fog : "Nebbia",
           Sand : "Sabbia",
           Ash : "Cenere",
           Squall : "Burrasca",
           Tornado : "Tornado",
           "Temperatur" : "temperatura",
           "Wind" : "vento",
           "WindDir-N" : "N",
           "WindDir-NE" : "NE",
           "WindDir-E" : "E",
           "WindDir-SE" : "SE",
           "WindDir-S" : "S",
           "WindDir-SW" : "SO",
           "WindDir-W" : "O",
           "WindDir-NW" : "NO",
           "WindDir-U" : ""
         },
         CZE : {
           Clouds : "zatažený",
           Thunderstorm : "Bouřka",
           Rain : "Déšť",
           Snow : "Sníh",
           Drizzle : "Mrholení",
           Clear : "Jasné Počasí",
           Mist : "Zamlžení",
           Smoke : "Kouř",
           Haze : "Opar",
           Dust : "Prach",
           Fog : "Mlha",
           Sand : "Písek",
           Ash : "Popel",
           Squall : "Bouřka",
           Tornado : "Tornádo",
           "Temperatur" : "teplota",
           "Wind" : "plyny",
           "WindDir-N" : "S",
           "WindDir-NE" : "SV",
           "WindDir-E" : "V",
           "WindDir-SE" : "JV",
           "WindDir-S" : "J",
           "WindDir-SW" : "JZ",
           "WindDir-W" : "Z",
           "WindDir-NW" : "SZ",
           "WindDir-U" : ""
         },
         POL : {
           Clouds : "pochmurny",
           Thunderstorm : "Burza z Piorunami",
           Rain : "Deszcz",
           Snow : "Śnieg",
           Drizzle : "Mżawka",
           Clear : "Bezchmurnie",
           Mist : "Mgiełka",
           Smoke : "Dym",
           Haze : "Dymek",
           Dust : "Pył",
           Fog : "Mgła",
           Sand : "Piasek",
           Ash : "Popiół",
           Squall : "Szkwał",
           Tornado : "Tornado",
           "Temperatur" : "temperatura",
           "Wind" : "wiatr",
           "WindDir-N" : "",
           "WindDir-NE" : "",
           "WindDir-E" : "",
           "WindDir-SE" : "",
           "WindDir-S" : "",
           "WindDir-SW" : "",
           "WindDir-W" : "",
           "WindDir-NW" : "",
           "WindDir-U" : ""
         },
      }
    }
  },
}