<template>
  <div id="app">
    <div class="frame">
      <div class="main">
        <div class="counterbar">
         <CounterBar />
        </div>
        <div class="site">
          <div id="page" class="tbcell" v-bind:class="{'has_right_border' : overview_visible}">
            <LPNavigation :sidebarvisible="overview_visible" />
            <Sponsors />
            <div id="main-view">
             <keep-alive :include="['chat', 'downloads']">
              <router-view />
             </keep-alive>
            </div>
          </div>
          <b-collapse v-model="overview_visible" id="collapse-overview">
          <div id="overview" class="tbcell">
            <div id="overview_order">
              <div class="overview_toggle">
                <b-button v-b-toggle.collapse-overview block size="sm" variant="outline-dark"> {{ t('Sidebar verstecken') }}</b-button>
              </div>
              <Weather />
              <Top5 />
              <EventStatus />
              <Overview />
              <Ticker />
            </div>
          </div>
          </b-collapse>
        </div>
      </div>
      <div class="links">
        <small>
          <router-link :to="racePath + '/about'">{{ t('Impressum') }}</router-link> |
          <router-link :to="racePath + '/privacy'">{{ t('Datenschutz') }}</router-link> |
          <router-link :to="racePath + '/credits'">{{ t('Credits') }}</router-link>
        </small>
      </div>
    </div>
    <Toasts :show-progress="true" :rtl="false" :max-messages="5" :time-out="3000" :closeable="true"></Toasts>
  </div>
</template>

<script>
import CounterBar from "@/components/CounterBar.vue";
import Sponsors from "@/components/Sponsors.vue";
import Overview from "@/components/Overview.vue";
import Top5 from "@/components/Top5.vue";
import Weather from "@/components/Weather.vue";
import EventStatus from "@/components/EventStatus.vue";
import Ticker from "@/components/Ticker.vue";
import LPNavigation from "@/components/LPNavigation.vue";

import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/App.js";

import Helper from "@/js-code/helper";

export default {
  mixins: [translationMixin, translationTableMixin],
  components: {
    CounterBar,
    Sponsors,
    Overview,
    Weather,
    EventStatus,
    Ticker,
    LPNavigation,
    Top5
  },
  metaInfo: {
    title: "Liveportal 3.0",
    htmlAttrs: {
      lang: "de"
    },
    meta: [
      {
        property: "og:locale",
        content: "de_DE"
      },
      {
        property: "og:url",
        content: "http://liveportal.berg-zeitnahme.net/"
      },
      {
        property: "og:site_name",
        content: "Berg-Zeitnahme Liveportal"
      },
      {
        property: "og:image",
        content: "http://www.berg-zeitnahme.net/multimedia/Logo_gross.png"
      }
    ]
  },
  data() {
    return {
      overview_visible : false,
    };
  },
  methods: {
  },
  computed: {
    race() {
      return this.$store.getters['race/resolve'];
    },
    racePath() {
      return "/race/" + this.$store.getters['race/currentParam'];
    },
  },
  created() {
    this.overview_visible = !Helper.isMobile();
  }
};
</script>

<style>
html {
  background-color: #b4c9ce;
}

body {
  margin-top: 0px;
}

#app {
  background-image: url(assets/bg.png);
  background-attachment: fixed;
  background-repeat: repeat-x;
  background-color: #b4c9ce;
  width: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  justify-content: center;
}

div.main {
  max-width: 1000px;
  display: table;
  background: white;
  border-left: 1px solid #444444;
  border-right: 1px solid #444444;
  border-bottom: 1px solid #444444;
}

@media (min-width: 1000px) {
  div.main {
    width: 1000px;
  }
}

div.counterbar {
  width: 100%;
}

div.site {
  border-top: 1px solid #444444;
  display: flex;
  width: 100%;
  min-height: 600px;
}

div.site div.tbcell {
  display: table-cell;
}

div.links {
  width: 100%;
  text-align: center;
}

#page {
  /* max-width: 800px; */
  width: 100%;
}

.has_right_border {
  border-right: 1px solid #444444;
}

.overview_toggle {
  display: block;
  width: 100%;
  background: #edeeff;
  text-align: center;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
}

#overview {
  /* border-left: 1px solid #444444; */
  display: flex;
  min-height: 800px;
  width: 200px;
}

#overview_order {
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

#main-view {
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.main_bg {
  background-image: url(./assets/LogoBg.png);
  background-position: center 50px;
  background-attachment: scroll;
  background-repeat: no-repeat;
  min-height: 400px;
  width: 100%;
}
</style>
