export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {
           "successMessageLogin" : "Sie wurden erfolgreich angemeldet",
           "errorMessageLogin" : "Anmeldung fehlgeschlagen",
           "successMessageRegister" : "Benutzer wurde erfolgreich registriert",
           "errorMessageRegister" : "Registrierung fehlgeschlagen",
           "successMessageChangePW" : "Passwort wurde erfolgreich geändert",
           "errorMessageChangePW" : "Passwortänderung fehlgeschlagen",
           "successMessageChangeData" : "Daten wurden erfolgreich geändert",
           "errorMessageChangeData" : "Datenänderung fehlgeschlagen",
           "successMessageResetPW" : "E-Mail mit dem Bestätigungs-Link wurde gesendet",
           "errorMessageResetPW" : "Zurücksetzen des Passworts fehlgeschlagen",
           "successMessageResetPWStep2" : "Passwort wurde neu gesetzt",
           "errorMessageResetPWStep2" : "Speichern des Passworts fehlgeschlagen",
           "successRegistrationConfirmation" : "Registrierung wurde erfolgreich aktiviert",
           "errorRegistrationConfirmation" : "Aktivierung leider fehlgeschlagen",
         },
         ENG : {
           "Anmeldung" : "Login",
           "Registrierung" : "Sign Up",
           "Passwort ändern" : "Change password",
           "Passwort zurücksetzen" : "Reset password",
           "Daten ändern" : "Change data",

           "Bitte E-Mail Adresse eingeben" : "Please enter your e-mail address",
           "E-Mail Adresse" : "E-mail address",
           "Wird zur Registierung und Anmeldung benötigt" : "Required for registration and login",
           "Benutzername" : "Username",
           "Bitte Benutzernamen eingeben" : "Please enter username",
           "Wird im Chat angezeigt" : "Displayed in the chatroom",
           "Name darf nicht leer sein" : "Username must not be empty",
           "Passwort" : "Password",
           "Bitte Passwort eingeben" : "Please enter your password",
           "Passwort darf nicht leer sein" : "Password must not be empty",
           "angemeldet bleiben" : "remember me",
           "Beide Passwörter müssen übereinstimmen" : "Both passwords must be equal",
           "Altes Passwort" : "Old password",
           "Bitte altes Passwort eingeben" : "Please enter old password",
           "Neues Passwort" : "New password",
           "Bitte neues Passwort eingeben" : "Please enter new password",
           "Neues Passwort (Wiederholung)" : "New password (repeated)",
           "Bitte neues Passwort eingeben (nochmal)" : "Please enter new password again",

           "Anmelden" : "Login",
           "Registrieren" : "Sign up",
           "Reset" : "Reset",
           "Passwort vergessen" : "Forgot password",


           "successMessageLogin" : "You have been logged in successfully",
           "errorMessageLogin" : "Login failed",
           "successMessageRegister" : "User has signed up successfully",
           "errorMessageRegister" : "Sign up failed",
           "successMessageChangePW" : "Password has been changed successfully",
           "errorMessageChangePW" : "Change of password failed",
           "successMessageChangeData" : "Data have been changed successfully",
           "errorMessageChangeData" : "Change of data failed",
           "successMessageResetPW" : "An e-mail with a confirmation-link has been sent",
           "errorMessageResetPW" : "Reset of the password failed",
           "successMessageResetPWStep2" : "Password has been reset",
           "errorMessageResetPWStep2" : "Password could not be saved",
           "successRegistrationConfirmation" : "Registration successfully activated",
           "errorRegistrationConfirmation" : "Activation failed",
         },
         FRA : {
           "Anmeldung" : "Connexion",
           "Registrierung" : "Inscription",
           "Passwort ändern" : "Changer le Mot de Passe",
           "Passwort zurücksetzen" : "Réinitialiser le mot de passe",
           "Daten ändern" : "Changer les Dates",

           "Bitte E-Mail Adresse eingeben" : "Veuillez saisir votre adresse e-mail",
           "E-Mail Adresse" : "Adresse e-mail",
           "Wird zur Registierung und Anmeldung benötigt" : "est requise pour l'inscription et la connexion",
           "Benutzername" : "Nom d'utilisateur",
           "Bitte Benutzernamen eingeben" : "Veuillez saisir un nom d'utilisateur",
           "Wird im Chat angezeigt" : "est affiché dans le tchat",
           "Name darf nicht leer sein" : "Le nom ne peut pas être vide",
           "Passwort" : "Mot de passe",
           "Bitte Passwort eingeben" : "Veuillez saisir le mot de passe",
           "Passwort darf nicht leer sein" : "Le mot de passe ne peut pas être vide",
           "angemeldet bleiben" : "rester connecté",
           "Beide Passwörter müssen übereinstimmen" : "Les deux mots de passe doivent correspondre",
           "Altes Passwort" : "Ancien mot de passe",
           "Bitte altes Passwort eingeben" : "Veuillez saisir l'ancien mot de passe",
           "Neues Passwort" : "Nouveau mot de passe",
           "Bitte neues Passwort eingeben" : "Veuillez saisir un nouveau mot de passe",
           "Neues Passwort (Wiederholung)" : "Nouveau mot de passe (Répéter)",
           "Bitte neues Passwort eingeben (nochmal)" : "Veuillez saisir un nouveau mot de passe (à nouveau)",

           "Anmelden" : "Connecter",
           "Registrieren" : "S'inscrire",
           "Reset" : "Remettre",
           "Passwort vergessen" : "J'ai oublié mon mot de passe",


           "successMessageLogin" : "",
           "errorMessageLogin" : "",
           "successMessageRegister" : "",
           "errorMessageRegister" : "",
           "successMessageChangePW" : "",
           "errorMessageChangePW" : "",
           "successMessageChangeData" : "",
           "errorMessageChangeData" : "",
           "successMessageResetPW" : "",
           "errorMessageResetPW" : "",
           "successMessageResetPWStep2" : "",
           "errorMessageResetPWStep2" : "",
         },
         ITA : {
           "Anmeldung" : "Login",
           "Registrierung" : "Registrazione",
           "Passwort ändern" : "Cambia Password",
           "Passwort zurücksetzen" : "Reimposta password",
           "Daten ändern" : "Cambia le Date",

           "Bitte E-Mail Adresse eingeben" : "Per favore inserisci il tuo indirizzo email",
           "E-Mail Adresse" : "Indirizzo email",
           "Wird zur Registierung und Anmeldung benötigt" : "richiesto per la registrazione e l'accesso",
           "Benutzername" : "Nome utente",
           "Bitte Benutzernamen eingeben" : "Inserisci il nome utente",
           "Wird im Chat angezeigt" : "è mostrato nella chat",
           "Name darf nicht leer sein" : "Il nome non può essere vuoto",
           "Passwort" : "Password",
           "Bitte Passwort eingeben" : "Inserisci la password",
           "Passwort darf nicht leer sein" : "La password non può essere vuota",
           "angemeldet bleiben" : "ricordati di me",
           "Beide Passwörter müssen übereinstimmen" : "Entrambe le password devono corrispondere",
           "Altes Passwort" : "Vecchia password",
           "Bitte altes Passwort eingeben" : "Inserisci la vecchia password",
           "Neues Passwort" : "Nuova password",
           "Bitte neues Passwort eingeben" : "Inserisci una nuova password",
           "Neues Passwort (Wiederholung)" : "Nuova password (ripeti)",
           "Bitte neues Passwort eingeben (nochmal)" : "Inserisci una nuova password (di nuovo)",

           "Anmelden" : "Accedi",
           "Registrieren" : "Registrazione",
           "Reset" : "Reset",
           "Passwort vergessen" : "Ho dimenticato la mia password",


           "successMessageLogin" : "",
           "errorMessageLogin" : "",
           "successMessageRegister" : "",
           "errorMessageRegister" : "",
           "successMessageChangePW" : "",
           "errorMessageChangePW" : "",
           "successMessageChangeData" : "",
           "errorMessageChangeData" : "",
           "successMessageResetPW" : "",
           "errorMessageResetPW" : "",
           "successMessageResetPWStep2" : "",
           "errorMessageResetPWStep2" : "",
         },
         CZE : {
           "Anmeldung" : "Přihlášení",
           "Registrierung" : "Registrace",
           "Passwort ändern" : "Změňte Heslo",
           "Passwort zurücksetzen" : "Obnovit Heslo",
           "Daten ändern" : "Změnit Data",

           "Bitte E-Mail Adresse eingeben" : "Zadejte prosím svou e-mailovou adresu",
           "E-Mail Adresse" : "E-mailová adresa",
           "Wird zur Registierung und Anmeldung benötigt" : "pro registraci a přihlášení ",
           "Benutzername" : "Uživatelské jméno",
           "Bitte Benutzernamen eingeben" : "Zadejte prosím uživatelské jméno",
           "Wird im Chat angezeigt" : "se zobrazuje v chatu",
           "Name darf nicht leer sein" : "Název nesmí být prázdný",
           "Passwort" : "Heslo",
           "Bitte Passwort eingeben" : "Zadejte heslo",
           "Passwort darf nicht leer sein" : "Heslo nemůže být prázdné",
           "angemeldet bleiben" : "zapamatuj si mě",
           "Beide Passwörter müssen übereinstimmen" : "Obě hesla se musí shodovat",
           "Altes Passwort" : "Staré heslo",
           "Bitte altes Passwort eingeben" : "Zadejte staré heslo",
           "Neues Passwort" : "Nové heslo",
           "Bitte neues Passwort eingeben" : "Zadejte nové heslo",
           "Neues Passwort (Wiederholung)" : "Nové heslo (opakování)",
           "Bitte neues Passwort eingeben (nochmal)" : "Zadejte nové heslo (znovu)",

           "Anmelden" : "Přihlášení",
           "Registrieren" : "Registrovat",
           "Reset" : "Resetovat",
           "Passwort vergessen" : "Zapomněl jsem své heslo",


           "successMessageLogin" : "",
           "errorMessageLogin" : "",
           "successMessageRegister" : "",
           "errorMessageRegister" : "",
           "successMessageChangePW" : "",
           "errorMessageChangePW" : "",
           "successMessageChangeData" : "",
           "errorMessageChangeData" : "",
           "successMessageResetPW" : "",
           "errorMessageResetPW" : "",
           "successMessageResetPWStep2" : "",
           "errorMessageResetPWStep2" : "",
         },
         POL : {
           "Anmeldung" : "Logowanie",
           "Registrierung" : "Rejestracja",
           "Passwort ändern" : "Zmień Hasło",
           "Passwort zurücksetzen" : "Zresetuj Hasło",
           "Daten ändern" : "Zmień Daty",

           "Bitte E-Mail Adresse eingeben" : "Proszę podać swój adres e-mail",
           "E-Mail Adresse" : "Adres e-mail",
           "Wird zur Registierung und Anmeldung benötigt" : "jest wymagany do rejestracji i logowania",
           "Benutzername" : "Nazwa użytkownika",
           "Bitte Benutzernamen eingeben" : "Proszę podać nazwę użytkownika",
           "Wird im Chat angezeigt" : "jest wyświetlane na czacie",
           "Name darf nicht leer sein" : "Nazwa nie może być pusta",
           "Passwort" : "Hasło",
           "Bitte Passwort eingeben" : "Proszę podać hasło",
           "Passwort darf nicht leer sein" : "Hasło nie może być puste",
           "angemeldet bleiben" : "zapamiętaj mnie",
           "Beide Passwörter müssen übereinstimmen" : "Oba hasła muszą się zgadzać",
           "Altes Passwort" : "Stare hasło",
           "Bitte altes Passwort eingeben" : "Proszę podać stare hasło",
           "Neues Passwort" : "Nowe hasło",
           "Bitte neues Passwort eingeben" : "Proszę podać nowe hasło",
           "Neues Passwort (Wiederholung)" : "Nowe hasło (powtórz)",
           "Bitte neues Passwort eingeben (nochmal)" : "Proszę podać nowe hasło (ponownie)",

           "Anmelden" : "Zaloguj",
           "Registrieren" : "Zarejestruj",
           "Reset" : "Zresetować",
           "Passwort vergessen" : "Zapomniałem hasła",


           "successMessageLogin" : "",
           "errorMessageLogin" : "",
           "successMessageRegister" : "",
           "errorMessageRegister" : "",
           "successMessageChangePW" : "",
           "errorMessageChangePW" : "",
           "successMessageChangeData" : "",
           "errorMessageChangeData" : "",
           "successMessageResetPW" : "",
           "errorMessageResetPW" : "",
           "successMessageResetPWStep2" : "",
           "errorMessageResetPWStep2" : "",
         },
      }
    }
  },
}