export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {

         },
         ENG : {
           "mit freundlicher Unterstützung von" : "with friendly support from"
         },
         FRA : {
           "mit freundlicher Unterstützung von" : "avec l'aimable soutien de"
         },
         ITA : {
           "mit freundlicher Unterstützung von" : "con il gentile supporto di"
         },
         CZE : {
           "mit freundlicher Unterstützung von" : "s laskavou podporou od"
         },
         POL : {
           "mit freundlicher Unterstützung von" : "z życzliwym wsparciem od"
         },
      }
    }
  },
}