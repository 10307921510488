import socketio from 'socket.io-client';

export default class RealtimeClient {
  constructor(namespace, contentReceiver, getBackend)
  {
    this.getBackend = getBackend;
    this.contentReceiver = contentReceiver;
    this.namespace = namespace;
    //this.baseURL = 'http://localhost:3000';
    this.baseURL = process.env.VUE_APP_SOCKETIO_HOST + ':' + process.env.VUE_APP_SOCKETIO_PORT;
    //console.log(this.baseURL);

    let self = this;
    this.connection = socketio(this.baseURL + namespace);
    if (this.connection === undefined)
      console.log(this.baseURL + namespace);

    this.connection.on('all', function(specifier, stateIndicator, content) {
      contentReceiver.updateAll(specifier, stateIndicator, content);
    }); 

    this.connection.on('merge', async function(specifier, previousIndicator, currentIndicator, content) {
      //console.log('rlnode - currentIndicator: ' + currentIndicator);
      //console.log('rlnode - previousIndicator: ' + previousIndicator);
      const mergeResult = contentReceiver.merge(specifier, previousIndicator, currentIndicator, content);
      if (!mergeResult.merged)
      {
        //console.log('not merged: try get individual update');
        try {
          //const completeResult = await self.getAll(specifier);
          //contentReceiver.updateAll(completeResult.specifier, completeResult.stateIndicator, completeResult.content);
          const individualUpdates = await self.getUpdates(specifier, mergeResult.stateIndicator);
          if (individualUpdates.resultType === 'update') {
            //console.log('got individual update');
            contentReceiver.merge(specifier, mergeResult.stateIndicator, individualUpdates.stateIndicator, individualUpdates.content);
          }
          else {
            //console.log('did not get individual update');
            contentReceiver.updateAll(individualUpdates.specifier, individualUpdates.stateIndicator, individualUpdates.content);
          }
        } 
        catch (e) {
          contentReceiver.handleError(e);
        }
      }
    });

    this.connection.on('connect', function() {
      const specifier = self.contentReceiver.getStandardSpecifier();
      self.connection.emit('subscribe', specifier);
    });
  }

  async getAll(specifier)
  {
    const backend = this.getBackend();
    const result = await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/getAll', specifier);
    if (result.status === 'ok')
      return result.data;
    else
      throw result.message;
  }

  async getUpdates(specifier, clientStateIndicator)
  {
    let query = JSON.parse(JSON.stringify(specifier));
    query.stateIndicator = clientStateIndicator;
    const backend = this.getBackend();
    const result = await backend.get(process.env.VUE_APP_BACKEND_URL + this.namespace + '/getUpdates', query);
    if (result.status === 'ok')
      return result.data;
    else
      throw result.message;
  }

  subscribe(specifier)
  {
    this.connection.emit('subscribe', specifier);
  }

  unsubscribe(specifier)
  {
    this.connection.emit('unsubscribe', specifier);
  }
}
