<template>
  <div class="LT_Document">
    <div class="currentClassification" v-if="document && document.groups">
      <LiveTimingBlockContent subClass="clsHeader" :content="document.content" />
      <div class="group" v-for="(g, index) in selectedGroups" :key="index">
        <LiveTimingBlockContent subClass="groupHeader" :content="g.content" :translator="translateGroupTitle" />
        <div class="groupContent" v-for="(sg, index) in g.subGroups" :key="index">
          <LiveTimingBlockContent subClass="subGroupHeader" :content="sg.content" />
          <LiveTimingTable :content="sg.table" :meta="document.meta" />
          <LiveTimingBlockContent subClass="subGroupFooter" :content="sg.contentAfter" />
        </div>
        <LiveTimingBlockContent subClass="groupFooter" :content="g.contentAfter" />
      </div>
      <LiveTimingBlockContent subClass="clsFooter" :content="document.contentAfter" />
    </div>
    <div class="noData" v-else>
      {{ t('Derzeit keine Daten vorhanden') }}
    </div>
  </div>
</template>

<script>
import LiveTimingBlockContent from "@/components/livetiming/LiveTimingBlockContent.vue";
import LiveTimingTable from "@/components/livetiming/LiveTimingTable.vue";

import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Results.js";
import { timingTranslationMixin } from "@/mixins/translations/live-timing/live-timing.js";

export default {
  mixins: [translationMixin, translationTableMixin, timingTranslationMixin],
  components: {
    LiveTimingBlockContent,
    LiveTimingTable
  },
  props: {
    document : {}, 
    selection : {
      default: null
    }
  },
  computed: {
    selectedGroups() {
      if (this.document.groups)
      {
        if (this.selection === null)
          return this.document.groups;
        else if (this.selection < this.document.groups.length)
          return [this.document.groups[this.selection]];
      }
      return [];
    },
  }
}
</script>

<style scoped>
.noData {
  text-align: center;
}

.currentClassification {
  margin-top: 20px;
}
</style>