export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {

         },
         ENG : {
           "Impressum" : "Imprint",
           "Vertretungsberechtigte" : "Representatives",
           "und" : "and"
         },
         FRA : {
           "Impressum" : "Empreinte",
           "Vertretungsberechtigte" : "Autorisé",
           "und" : "et"
         },
         ITA : {
           "Impressum" : "Impronta",
           "Vertretungsberechtigte" : "Autorizzato",
           "und" : "e"
         },
         CZE : {
           "Impressum" : "Otisk",
           "Vertretungsberechtigte" : "Oprávněný",
           "und" : "a"
         },
         POL : {
           "Impressum" : "Znak Firmowy Wydawcy",
           "Vertretungsberechtigte" : "Upoważniony",
           "und" : "i"
         },
      }
    }
  },
}