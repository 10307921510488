import store from "../store/index.js";

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Privacy from "../views/Privacy.vue";
import Credits from "../views/Credits.vue";
import Starters from "../views/Starters.vue";
import Results from "../views/Results.vue";
import Downloads from "../views/Downloads.vue";
import Chat from "../views/Chat.vue";
import Register from "../views/User-Management/Register.vue";
import ConfirmRegistration from "../views/User-Management/ConfirmRegistration.vue";
import Login from "../views/User-Management/Login.vue";
import RequestPasswordReset from "../views/User-Management/RequestPasswordReset.vue";
import ResetPassword from "../views/User-Management/ResetPassword.vue";
import ChangePassword from "../views/User-Management/ChangePassword.vue";
import ChangeData from "../views/User-Management/ChangeData.vue";
import TickerEntry from "../views/Administration/TickerEntry.vue";
import CreateRace from "../views/Administration/CreateRace.vue";
import SelectRace from "../views/Administration/SelectRace.vue";
import DisplayLog from "../views/Administration/DisplayLog";
import DisplayEventSettings from "@/views/Administration/DisplayEventSettings.vue";

Vue.use(VueRouter);

function protectAdminRoute(to, from, next) {
  if (!store.getters['userManagement/userIsAdmin'])
    next('/');
  else
    next();
}

function protectRoleRoute(role) {
  return function(to, from, next) {
    if (!store.getters['userManagement/userHasRole'](role))
      next('/');
    else
      next();
  }
}

const racePath = "/race/:race";

const basicHomeRoute = {
  path: "/",
  name: "basicHome",
  component: Home
};

const homeRoute = {
  path: racePath + "/",
  name: "home",
  component: Home
};

const aboutRoute = {
  path: racePath + "/about",
  name: "about",
  component: About
};

const privacyRoute = {
  path: racePath + "/privacy",
  name: "privacy",
  component: Privacy
};

const creditsRoute = {
  path: racePath + "/credits",
  name: "credits",
  component: Credits
};

const startersRoute = {
  path: racePath + "/starters",
  name: "starters",
  component: Starters
};

const resultsRoute = {
  path: racePath + "/results",
  name: "results",
  component: Results
};

const downloadsRoute = {
  path: racePath + "/downloads",
  name: "downloads",
  component: Downloads
};

const chatRoute = {
  path: racePath + "/chat",
  name: "chat",
  component: Chat
};

const registerRoute = {
  path: "/register",
  name: "register",
  component: Register
}

const confirmRegistrationRoute = {
  path: "/confirmRegistration",
  name: "confirmRegistration",
  component: ConfirmRegistration
}

const loginRoute = {
  path: "/login",
  name: "login",
  component: Login
}

const requestPasswordResetRoute = {
  path: "/requestPasswordReset",
  name: "requestPasswordReset",
  component: RequestPasswordReset
}

const resetPasswordRoute = {
  path: "/resetPassword",
  name: "resetPassword",
  component: ResetPassword
}

const changePasswordRoute = {
  path: "/changePassword",
  name: "changePassword",
  component: ChangePassword
}

const changeDataRoute = {
  path: "/changeData",
  name: "changeData",
  component: ChangeData
}

const tickerEntryRoute = {
  path: "/admin/postTickerEntry",
  name: "postTickerEntry",
  component: TickerEntry,
  beforeEnter: protectRoleRoute('ticker'),
}

const createRaceRoute = {
  path: "/admin/createRace",
  name: "createRace",
  component: CreateRace,
  beforeEnter: protectAdminRoute,
}

const selectRaceRoute = {
  path: "/admin/selectRace",
  name: "selectRace",
  component: SelectRace,
  beforeEnter: protectAdminRoute,
}

const displayEventSettingsRoute = {
  path: "/admin/displayEventSettings",
  name: "displayEventSettings",
  component: DisplayEventSettings,
  beforeEnter: protectAdminRoute,
}

const displayResultLogRoute = {
  path: "/admin/displayResultLog",
  name: "displayResultLog",
  component: DisplayLog,
  beforeEnter: protectAdminRoute,
}

const routes = [
  basicHomeRoute,
  homeRoute,
  aboutRoute,
  privacyRoute,
  creditsRoute,
  startersRoute,
  resultsRoute,
  downloadsRoute,
  chatRoute,
  registerRoute,
  confirmRegistrationRoute,
  loginRoute,
  requestPasswordResetRoute,
  resetPasswordRoute,
  changePasswordRoute,
  changeDataRoute,
  tickerEntryRoute,
  createRaceRoute,
  selectRaceRoute,
  displayEventSettingsRoute,
  displayResultLogRoute
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
