<template>
  <div class="create_race">
    <pageTitle>{{ t('aktuelles Rennen auswählen') }}</pageTitle>
    <div class="current">
      {{ t('aktuelles Rennen') }}: <strong>{{ current }}</strong>
    </div>
    <div class="select">
      {{ t('Auswahl') }}:
      <b-form @submit="onSubmit">
        <b-form-select v-model="selected" :options="list" :select-size="15"></b-form-select>
        <div class="mt-3 selectionIndicator">{{ t('ausgewählt') }}: <strong>{{ selected }}</strong></div>

        <b-button type="submit" variant="primary">{{ t('Rennen auswählen') }}</b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Administration/RaceManagement.js";

export default {
  mixins: [translationMixin, translationTableMixin],
  data() {
    return {
      list : [],
      current : "",
      selected : ""
    }
  },
  methods: {
    async getCurrentRace() {
      const backend = this.$store.state.backend;
      const result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/admin/race/getCurrent');
      if (result.status === 'ok') {
        if (result.data.status === 'ok')
          this.current = result.data.current;
      }
    },

    async getListOfRaces() {
      const backend = this.$store.state.backend;
      const result = await backend.get(process.env.VUE_APP_BACKEND_URL + '/admin/race/list');
      if (result.status === 'ok') {
        if (result.data.status === 'ok')
        {
          this.list = result.data.list;
        }
      }
    },

    async onSubmit(evt) {
      evt.preventDefault();
      if (this.selected !== "")
      {
        const backend = this.$store.state.backend;
        const result = await backend.post(process.env.VUE_APP_BACKEND_URL + '/admin/race/select', {name: this.selected});
        if (result.status === 'ok') {
          if (result.data.status === 'ok')
          {
            this.current = result.data.current;
            await this.$store.dispatch('race/refresh');
            this.$notifications.success(this.t('Rennen wurde erfolgreich ausgewählt') + "!");
          }
          else
            this.$notifications.error(result.data.message);
        }
        else
        {
          this.$notifications.error(result.message);
        }     
      }
    },
  },
  mounted() {
    this.getListOfRaces();
    this.getCurrentRace();
  }
}
</script>

<style scoped>
.current {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #edeeff;
  border: 1px dotted #CBCCDD;
}

.selectionIndicator {
  padding-left: 5px;
  margin-bottom: 10px;
}

</style>