<template>
  <div class="weather">
    <span v-if="received">
      <div style="display: table;">
        <div class="icon"><img :src="iconURL" height="50" /></div>
        <div class="weather_text">
          <span class="weather_text_main">{{ weatherText }}</span><br />
          {{ t('Temperatur') }}: <span class="temp" :style="'border: 1px dotted ' + formatTemp.bg + '; color: ' + formatTemp.color">{{ temp }} °C</span><br />
          {{ t('Wind') }}: {{ wind.speed }} km/h, {{ t('WindDir-'+windDirection) }}
        </div>
      </div>
    </span>
    <span v-else></span>
  </div>
</template>

<script>
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/Weather.js";

export default {
  mixins: [translationMixin, translationTableMixin],

  computed : {
    data() {
      return this.$store.getters['weather/data'];
    },

    received() {
      return this.$store.getters['weather/received'];
    },

    weather() {
      return this.data.weather;
    },

    wind() {
      return this.data.wind;
    },

    temp() {
      return this.data.temp;
    },

    iconURL() {
      return `https://openweathermap.org/img/wn/${this.weather.icon}@2x.png`;
    },

    weatherText() {
      return (this.weather.description && this.weather.description != "") ? this.t(this.weather.description) : this.t(this.weather.main);
    },

    windDirection() {
      switch (Math.floor(Math.round(this.wind.deg / 45))) {
        case 0: return "N";
        case 1: return "NE";
        case 2: return "E";
        case 3: return "SE";
        case 4: return "S";
        case 5: return "SW";
        case 6: return "W";
        case 7: return "NW";
      default:
        return "U";
      }
    },
    formatTemp() {
      const value = this.temp;

      if (value <= 0)
        return {bg: "navy", color: "black"};
      else if (value <= 5)
        return {bg: "#0000FF", color: "black"};
      else if (value <= 12)
        return {bg: "#00AAFF", color: "black"};
      else if (value <= 18)
        return {bg: "#00AA00", color: "black"};
      else if (value <= 25)
        return {bg: "#44DD00", color: "black"};
      else if (value <= 30)
        return {bg: "#DDEE00", color: "black"};
      else if (value <= 35)
        return {bg: "#FFAA00", color: "black"};
      else
        return {bg: "#DD0000", color: "black"};
    }
  },
}
</script>

<style scoped>
.weather {
  background: #edeeff;
  height: 80px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10pt;
  display: flex;
  margin-top: 0px;
  border-bottom: 1px dotted #CBCCDD;
}

.icon {
  width: 60px;
  display: table-cell;
  vertical-align: middle;
}

.weather_text {
  width: 140px;
  display: table-cell;
  justify-content: left;
  align-items: left;
  text-align: left;
  font-size: 9pt;
}

.weather_text_main {
  font-size: 11pt;
  font-weight: bold;
}

.temp {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
</style>