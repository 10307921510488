<template>
  <div class="overviewtable">
    <table class="times" v-if="items">
      <tr :class="getRowClass(rIndex, items.body.rows.length)" v-for="(r, rIndex) in items.body.rows" :key="rIndex">
        <td class="cell" v-for="(c, cIndex) in r.cells" :key="'c'+rIndex+'-'+cIndex">
         <span :class="['cellSpan', 'meta_' + items.meta[cIndex], 'meta_' + items.meta[cIndex] + '_' + r.cells.length]">
            <LTCell :content="c" />
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import LTCell from "@/components/livetiming/LTCell.vue";

export default {
  props: {
    content : Object,
    maxRows : {
      type: Number,
      default: 0
    },
    desc : {
      type: Boolean,
      default: false
    },
    separateLastCell : {
      type : Boolean,
      default : false
    }
  },
  components: {
    LTCell
  },
  methods: {
    resolveText(cell) {
      if (!cell)
        return '';
      else
      {
        if (cell.units)
        {
          let result = '';
          for (let u of cell.units)
            if (u.unitType == "text")
              result = result + u.text;
          return result;
        }
        else if (cell.text)
          return cell.text;
        else
          return '';
      }
    },
    getRowClass(rIndex, total) {
      let result = ['row'];
      if (this.separateLastCell && rIndex == total-1)
        result.push('topSeparatedRow');
      return result;
    }
  },
  computed: {
    items() {
      if (!this.content)
        return null;
      let result = {};
      result.head = this.content.head;
      result.meta = [];
      for (let r of result.head.rows)
        for (let c of r.cells)
          if (c.meta)
            result.meta.push(c.meta.trim());
          else
            result.meta.push('');
      result.body = {};
      result.body.rows = [];
      if (this.content.datasets)
      {
        for (let ds of this.content.datasets)
          for (let r of ds.rows)
          {
            if (this.maxRows == 0 || result.body.rows.length < this.maxRows)
              result.body.rows.push(r);
          }
      }
      if (this.desc)
        result.body.rows = result.body.rows.reverse();
      return result;
    },
  }
}
</script>

<style scoped>
.overviewtable {
  margin-left: 17px;
  width: 100%;
}

.times {
  width: 100%;
  font-size: 9pt;
}

.topSeparatedRow {
  color: #000033;
}

.topSeparatedRow .cell {

}

.topSeparatedRow .cell .cellSpan {
  margin-top: 4px;
}

.meta_rank {
  display: inline-block;
  width: 17px;
  padding-left: 3px;
}

.meta_ID {
  display: inline-block;
  background: #CBCCDD;
  text-align: center;
  font-weight: bold;
  width: 28px;
}

.meta_totalTime {
  display: inline-block;
  font-weight: bold;
}

.meta_name {
  padding-left: 0px;
  margin-left: 0px;
  display: flex;
  overflow-x: hidden;
  margin-right: 0px;
  width: 85px;
}

.meta_name_2 {
  margin-left: 3px;
  width: auto;
}

.meta_name_3 {
  margin-left: 0px;
}

.meta_name_4 {
}
</style>