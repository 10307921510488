<template>
  <div class="counter">
    <div class="counter_row">
      <div class="table_cell">
        {{ t('Besucher') }}: <counter type="general" /> <br v-if="mobileLineBreakActive" />({{ t('akt. Veranstaltung') }}: <counter type="race" />)
      </div>
      <div class="table_cell">
        {{ t('Besucher online') }}: <counter type="online" /> <br v-if="mobileLineBreakActive" />({{ t('im Chat') }}: <counter type="online_chat" />, {{ t('Stream') }}: <counter type="online_stream" />)
      </div>
    </div>
  </div>
</template>

<script>
import Counter from "@/components/counter/Counter.vue";
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/CounterBar.js";

export default {
  mixins: [translationMixin, translationTableMixin],

  name: "CounterBar",
  components: {
    Counter
  },
  data: () => {
    return {
    };
  },
  computed: {
    mobileLineBreakActive() {
      return window.matchMedia("(max-width: 660px)").matches;
    }
  }
};
</script>

<style scoped>
.counter {
  width: 100%;
  height: 18px;
  display: table;
  text-align: left;
  background: #c4d7e8;
  border-spacing: 0;
}

.counter_row {
  width: 100%;
  display: table-row;
}

.table_cell {
  width: 50%;
  display: table-cell;
  font-size: 8pt;
  padding-left: 5px;
  vertical-align: middle;
}
</style>
