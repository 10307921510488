<template>
 <div class="newTickerEntry">
  <pageTitle>{{ t('Einen Ticker-Eintrag erstellen') }}</pageTitle>
    <b-form @submit="onSubmit" @reset="onReset" id="tickerForm">
      <br />
      <div class="special_buttons">
        <b-button pill type="button" @click="submitInterruption" variant="warning">Unterbrechung</b-button>
        <b-button pill type="button" @click="submitReturn" variant="secondary" style="margin-left: 20px;">Rückführung</b-button>
        <b-button pill type="button" @click="submitContinuation" variant="success" style="margin-left: 20px;">Es geht weiter</b-button>
      </div>

      <br />

      <b-form-group 
        id="input-group-message" 
        :label="textMessage + ':'"
        label-for="input-message">
        <b-form-input
          id="input-message"
          v-model="form.message"
          required
          :state="validation"
          :placeholder="textPlaceholderMessage"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation">{{ t('Nachricht darf nicht leer sein') }}.</b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary">{{ t('Info schicken') }}</b-button>
      <b-button type="reset" variant="danger">{{ t('Reset') }}</b-button>
    </b-form>
 </div>
</template>

<script>
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Administration/TickerEntry.js";

export default {
  mixins: [translationMixin, translationTableMixin],
  data() {
    return {
      prevRoute: null,
      form: {
        message: ''
      }
    }
  },
  computed: {
    validation() {
      return this.form.message.length > 0;
    },

    textMessage() {
      return this.t('Nachricht');
    },

    textPlaceholderMessage() {
      return this.t('Bitte Nachricht eingeben');
    }
  },
  methods: {
    doSubmit() {
      if (this.submissionOk()) {
        const backend = this.$store.state.backend;
        const promise = backend.post(process.env.VUE_APP_BACKEND_URL + '/ticker/postEntry', {message: this.form.message});
        promise.then((function (result) {
          if (result.status == 'ok')
          {
            if (result.data.status == 'ok')
            {
              this.$toast.success(this.t('Nachricht wurde gepostet')+'!');
              this.$router.push(this.prevRoute);
            }
            else
              this.$notifications.error(result.data.message);
          }
          else
          {
            this.$notifications.error(result.message);
          }
        }).bind(this)).catch((function (error) {
          this.$notifications.error(error);
        }).bind(this));
      }
    },

    onSubmit(evt) {
      evt.preventDefault()
      this.doSubmit();
    },

    onReset(evt) {
      evt.preventDefault()
      this.clearData()
    },

    clearData() {
      this.form.name = '';
    },

    submissionOk() {
      return this.validation;
    },

    submitInterruption() {
      this.form.message = 'Unterbrechung';
      this.doSubmit();
    },

    submitReturn() {
      this.form.message = 'Rückführung';
      this.doSubmit();
    },

    submitContinuation() {
      this.form.message = 'Es geht weiter';
      this.doSubmit();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
}
</script>

<style scoped>
.special_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>