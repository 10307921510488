import Statistics from "../js-code/statistics.js"

var moduleStatistics = {
  namespaced: true,

  state: {
    updater: null,
    counter: undefined
  },

  getters: {
    counter: (state) => (type) => {
      return (state.counter && state.counter[type] !== undefined) ? state.counter[type] : -1;
    }
  },

  mutations: {
    setUpdater(state, value) {
      state.updater = value;
    },

    setCounter(state, value) {
      state.counter = value;
    },
  },

  actions: {
    async init(context) {
      context.commit('setUpdater', new Statistics(context));
    },
  }
}

export {
  moduleStatistics
}