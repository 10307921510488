<template>
  <div class="overviewcomponent starting">
   <table>
     <tr>
       <td style="width: 20px">
          <div class="img">
            <div><img src="../../assets/overview/start.png" /></div>
          </div>
       </td>
       <td style="width: 180px;">
         <OverviewTable :content="currentContent" />
       </td>
     </tr>
   </table>
  </div>
</template>

<script>
import OverviewTable from "@/components/OverviewTable.vue";

export default {
  components: {
    OverviewTable
  },
  data() {
    return {
    }
  },
  computed: {
    currentContent() {
      const result = JSON.parse(this.$store.getters['liveTiming/results']('NextStarters'));
      let table = null;
      if (result && result.groups.length > 0 && result.groups[0].subGroups.length > 0)
        table = result.groups[0].subGroups[0].table;
      return table;
    }
  }
}
</script>

<style scoped>
@import "../../assets/overview/overview.css";

.starting {
  background-image: url(../../assets/overview/start_bg.png);
}
</style>