<template>
  <b-navbar toggleable="lg" type="light" variant="light">
   <b-navbar-brand href="#"><img src="../assets/bzn_logo.png" height="35" /></b-navbar-brand>

   <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

   <b-collapse id="nav-collapse" is-nav>
    <b-navbar-nav>
      <b-nav-item-dropdown text="Basics">
       <b-dropdown-item :to="racePath + '/'"><b-icon icon="house" class="menu_icon" /> Home</b-dropdown-item>
       <b-dropdown-item :to="racePath + '/about'"><b-icon icon="info-circle" class="menu_icon" /> {{ t('Impressum') }}</b-dropdown-item>
       <b-dropdown-item :to="racePath + '/privacy'"><b-icon icon="eye-slash-fill" class="menu_icon" /> {{ t('Datenschutz') }}</b-dropdown-item>
       <b-dropdown-item :to="racePath + '/credits'"><b-icon icon="puzzle-fill" class="menu_icon" /> {{ t('Credits') }}</b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown :text="t('Timing')">
       <b-dropdown-item :to="racePath + '/starters'"><b-icon icon="table" class="menu_icon" /> {{ t('Starterliste') }}</b-dropdown-item>
       <b-dropdown-item :to="racePath + '/results'"><b-icon icon="trophy" variant="warning" class="menu_icon" /> Live-Timing</b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown text="Multimedia">
       <b-dropdown-item :href="streamingLink" target="_blank"><b-icon icon="camera-video" variant="danger" class="menu_icon" /> Stream</b-dropdown-item>
       <b-dropdown-item :to="racePath + '/downloads'"><b-icon icon="download" class="menu_icon" /> Downloads</b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item :to="racePath + '/chat'"><b-icon icon="chat-dots" variant="info" class="menu_icon" />Chat</b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">

      <b-nav-item-dropdown :text="userTitle" class="menu_user" right>
        <b-dropdown-item to="/login" v-show="!userLoggedIn"><b-icon icon="box-arrow-right" class="menu_icon" /> {{ t('Anmelden') }}</b-dropdown-item>
        <b-dropdown-item to="/register" v-show="!userLoggedIn"><b-icon icon="people-fill" class="menu_icon" /> {{ t('Registrieren') }}</b-dropdown-item>

        <b-dropdown-item @click="logoutUser()" v-show="userLoggedIn"><b-icon icon="box-arrow-left" class="menu_icon" /> {{ t('Abmelden') }}</b-dropdown-item>
        <b-dropdown-item to="/changePassword" v-show="userLoggedIn"><b-icon icon="shield-lock" class="menu_icon" /> {{ t('Passwort ändern') }}</b-dropdown-item>
        <b-dropdown-item to="/changeData" v-show="userLoggedIn"><b-icon icon="person-lines-fill" class="menu_icon" /> {{ t('Daten ändern') }}</b-dropdown-item>
        <b-dropdown-divider v-show="userLoggedIn && userHasRole('ticker')" />
        <b-dropdown-item to="/admin/postTickerEntry" v-show="userLoggedIn && userHasRole('ticker')"><b-icon icon="chat-square-dots" class="menu_icon" /> {{ t('Tickereintrag erstellen') }}</b-dropdown-item>
        <b-dropdown-divider v-show="userLoggedIn && userIsAdmin" />
        <b-dropdown-item to="/admin/createRace" v-show="userLoggedIn && userIsAdmin">
          <b-iconstack class="menu_icon">
            <b-icon icon="cone-striped" font-scale="0.8" />
            <b-icon icon="plus" variant="success" font-scale="0.7" shift-v="7" shift-h="10" />
          </b-iconstack>
          {{ t('Rennen anlegen') }}
        </b-dropdown-item>
        <b-dropdown-item to="/admin/selectRace" v-show="userLoggedIn && userIsAdmin">
          <b-iconstack class="menu_icon">
            <b-icon icon="cone-striped" font-scale="0.8" />
            <b-icon icon="check" variant="info" font-scale="0.7" shift-v="7" shift-h="10" />
          </b-iconstack>
          {{ t('Rennen auswählen') }}
        </b-dropdown-item>
        <b-dropdown-divider v-show="userLoggedIn && userIsAdmin" />
        <b-dropdown-item to="/admin/displayEventSettings" v-show="userLoggedIn && userIsAdmin">
          <b-icon icon="list" class="menu_icon" />
          {{ t('Event-Einstellungen anzeigen') }}
        </b-dropdown-item>
        <b-dropdown-item to="/admin/displayResultLog" v-show="userLoggedIn && userIsAdmin">
          <b-icon icon="file-code" class="menu_icon" />
          {{ t('Ergebnislog anzeigen') }}
        </b-dropdown-item>
        <b-dropdown-divider v-show="userLoggedIn && userIsAdmin" />
        <b-dropdown-item @click="updateResults()" v-show="userLoggedIn && userIsAdmin">
          <b-icon icon="arrow-repeat" class="menu_icon" /> {{ t('Ergebnisse aktualisieren') }}
        </b-dropdown-item>
        <b-dropdown-item @click="updateCache()" v-show="userLoggedIn && userIsAdmin">
          <b-icon icon="arrow-repeat" variant="warning" class="menu_icon" /> {{ t('Cache auffrischen') }}
        </b-dropdown-item>
        <b-dropdown-item @click="checkCurrentEvents()" v-show="userLoggedIn && userIsAdmin">
          <b-icon icon="arrow-repeat" variant="primary" class="menu_icon" /> {{ t('Aktive Veranstaltungen laden') }}
        </b-dropdown-item>
        <b-dropdown-item @click="restartClassification()" v-show="userLoggedIn && userIsAdmin">
          <b-icon icon="arrow-repeat" variant="danger" class="menu_icon" /> {{ t('Auswertung neu starten') }}
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown class="menu_language" right>
        <template v-slot:button-content>
          <span v-if="currentLanguage"><img :src="getLangImgPath(currentLanguage)" height="20" /></span>
          <span v-else>Sprache</span>
        </template>
        <b-dropdown-item v-for="(lang, index) in languages" :key="'lang' + lang.shortcut + index" @click="setLanguage(lang)"><img :src="getLangImgPath(lang)" height="22" class="lang_flag" /> {{ lang.shortcut }}</b-dropdown-item>
      </b-nav-item-dropdown>

     <b-nav-form>
       <b-button v-b-toggle.collapse-overview size="sm" variant="outline-dark" v-if="!sidebarvisible">{{ t('Sidebar an') }}</b-button>
     </b-nav-form>
    </b-navbar-nav>
   </b-collapse>
  </b-navbar>
</template>

<script>
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/LPNavigation.js";

export default {
  mixins: [translationMixin, translationTableMixin],
  props: ["sidebarvisible"],
  data() {
    return {
      streamingLinkStd: "https://streaming.berg-zeitnahme.net/"
    }
  },
  computed: {
    languages() {
      return this.$store.getters['language/languages'];
    },
    racePath() {
      return "/race/" + this.$store.getters['race/currentParam'];
    },
    userLoggedIn() {
      return this.$store.getters['userManagement/userLoggedIn'];
    },
    userIsAdmin() {
      return this.$store.getters['userManagement/userIsAdmin'];
    },
    userTitle() {
      if (this.userLoggedIn)
        return this.$store.getters['userManagement/userName'];
      else
        return this.t('Benutzer');
    },
    streamingLink() {
      const settings = this.$store.getters['localSettings/data'];
      if (settings && settings.root && settings.root.streaming) {
        let streaming = settings.root.streaming;
        if (streaming && !Array.isArray(streaming))
          streaming = [streaming];

        const now = Date.now();
        if (streaming) {
          for (let entry of streaming) {
            let from = new Date(Date.parse(entry.from));
            let to = new Date(Date.parse(entry.to));
            const fromOk = (!entry.from || now >= from);
            const toOk = (!entry.to || now <= to);
            if (fromOk && toOk)
              return entry.link;
          }
        }
      }
      return this.streamingLinkStd;
    }
  },
  methods: {
    getLangImgPath: function(lang) {
      return require('../assets/language/new/' + lang.img);
    },

    onLogoutAttempted: function(result) {
      if (result.status === 'ok')
        this.$notifications.success(this.t('logoutMessage'));
    },

    userHasRole(role) {
      return this.$store.getters['userManagement/userHasRole'](role);
    },

    logoutUser() {
      this.$store.dispatch('userManagement/logout', this.onLogoutAttempted.bind(this));
    },

    onNotificationSent(result) {
      if (result.status === 'ok')
        this.$notifications.success(this.t('Benachrichtigung gesendet'));
    },

    updateResults() {
      this.$store.dispatch('liveTiming/updateResults', this.onNotificationSent.bind(this));
    },

    updateCache() {
      this.$store.dispatch('liveTiming/updateCache', this.onNotificationSent.bind(this));
    },

    restartClassification() {
      this.$store.dispatch('liveTiming/restartClassification', this.onNotificationSent.bind(this));
    },

    checkCurrentEvents() {
      this.$store.dispatch('liveTiming/checkCurrentEvents', this.onNotificationSent.bind(this));
    },

    setLanguage(lang) {
      this.$store.commit('language/set', lang);
    }
  }
}
</script>

<style scoped>
.menu_icon {
  margin-right: 5px;
}

.menu_language img {
  margin-right: 10px;
}

.lang_flag {
  border: 1px solid #DDDDDD;
}
</style>