<template>
  <div :class="subClass">
    <span v-if="content">
      <span v-for="(e, index) in myContent.elements" :key="index">
        <LTCell v-if="e.type == 'cell'" :content="e" :translator="translator" />
      </span>
    </span>
  </div>
</template>

<script>
import LTCell from "@/components/livetiming/LTCell.vue";

export default {
  name: "LiveTimingBlockContent",
  components: {
    LTCell,
  },
  props: {
    "subClass" : {}, 
    "content" : {},
    "translator" : {
       default: null
     },
  },
  computed: {
    myContent() {
      return this.content;
    }
  }
}
</script>

<style scoped>
</style>