export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {
           "HinweisText" : "Es ist strengstens untersagt, folgende Dokumente extern zu verlinken oder Kopien davon auf anderen Websites zu veröffentlichen! Sie geben lediglich eine Momentaufnahme der Veranstaltung wieder und haben keineswegs offiziellen Charakter, daher sind sie rein für den privaten, nichtkommerziellen Gebrauch bestimmt. Dies gilt insbesondere auch für Veröffentlichungen in Foren, Gästebüchern etc.",
         },
         ENG : {
           "Hinweis" : "Note",
           "Dateien" : "Files",
           "HinweisText" : "It is strictly forbidden to link the following documents externally or to publish copies of them on other websites! They only represent a snapshot of the event and are in no way official in nature, which is why they are purely for private, non-commercial use. This particularly applies to publications in forums, guest books etc.",
         },
         FRA : {
           "Hinweis" : "Remarque",
           "Dateien" : "Fichiers",
           "HinweisText" : "Il est strictement interdit de lier les documents suivants en externe ou d'en publier des copies sur d'autres sites! Ils ne sont qu'un instantané de l'événement et ne sont en aucun cas de nature officielle, ils sont donc destinés à un usage privé et non commercial uniquement. Cela vaut en particulier pour les publications dans les forums, les livres d'or, etc.",
         },
         ITA : {
           "Hinweis" : "Nota",
           "Dateien" : "File",
           "HinweisText" : "È severamente vietato collegare i seguenti documenti esternamente o pubblicarne copie su altri siti Web! Rappresentano solo un'istantanea dell'evento e non sono in alcun modo di natura ufficiale, motivo per cui sono puramente per uso privato e non commerciale. Ciò vale in particolare per pubblicazioni su forum, libri degli ospiti, ecc.",
         },
         CZE : {
           "Hinweis" : "Poznámka",
           "Dateien" : "Soubory",
           "HinweisText" : "Je přísně zakázáno propojovat následující dokumenty externě nebo publikovat jejich kopie na jiných webových stránkách! Představují pouze snímek události a v žádném případě nejsou oficiální povahy, proto jsou čistě pro soukromé, nekomerční použití. To se týká zejména publikací na fórech, knihách návštěv atd."
         },
         POL : {
           "Hinweis" : "Wskazówka",
           "Dateien" : "Pliki",
           "HinweisText" : "Surowo zabrania się linkowania poniższych dokumentów na zewnątrz lub publikowania ich kopii na innych stronach internetowych! Stanowią jedynie migawkę wydarzenia i nie są w żaden sposób oficjalne, dlatego są przeznaczone wyłącznie do prywatnego, niekomercyjnego użytku. Dotyczy to w szczególności publikacji na forach, książek gości itp."
         },
      }
    }
  },
}