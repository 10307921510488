import { translationTableBackendMessages } from "@/mixins/translations/ErrorMessages.js"

export default class MessageFormatter {
  constructor(storeContext)
  {
    this.storeContext = storeContext;
  }

  format(code)
  {
    const langId = this.storeContext.rootGetters['language/current'].id;
    const map = translationTableBackendMessages.data().translation;

    //console.log(langId);
    //console.log(map);

    let message = `Es ist ein Fehler (${code}) aufgetreten`;
    if (map[langId] && map[langId][code])
      return map[langId][code];
    if (langId != 'DEU' && map['ENG'] && map['ENG'][code])
      return map['ENG'][code];
    if (map['DEU'] && map['DEU'][code])
      return map['DEU'][code];

    return message;
  }
}