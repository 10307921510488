<template>
  <Bar
    id="live-timing-bar-chart"
    :chartOptions="mergedOptions"
    :chartData="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  components: { Bar },
  props: {
    chartData : {},
    options : {
      default: function() { return {}; }
    }
  },
  data() {
    return {
      defaultOptions : {
        animation: false,
        scales: {
          y: {
            ticks: {
                beginAtZero: true
            }
          }
        }
      }
    }
  },
  computed: {
    mergedOptions() {
      let result = {};
      Object.assign(result, this.defaultOptions, this.options);
      if (result.scales.y.ticks)
        result.scales.y.ticks.beginAtZero = true;
      return result;
    }
  },
}
</script>