export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {

         },
         ENG : {
           "Ticker" : "Ticker"
         },
      }
    }
  },
}