<template>
 <div class="create_race">
  <pageTitle>{{ t('Rennen anlegen') }}</pageTitle>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group 
        id="input-group-username" 
        :label="textRace + ':'"
        label-for="input-username">
        <b-form-input
          id="input-username"
          v-model="form.name"
          required
          :state="validation"
          :placeholder="textPlaceholderRace"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation">{{ t('Rennen darf nicht leer sein') }}.</b-form-invalid-feedback>
      </b-form-group>

      <b-button type="submit" variant="primary">{{ t('Rennen anlegen') }}</b-button>
      <b-button type="reset" variant="danger">{{ t('Reset') }}</b-button>
    </b-form>
 </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin.js";
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Administration/RaceManagement.js";

export default {
  mixins: [translationMixin, translationTableMixin, formMixin],
  data() {
    return {
      form: {
        name: ''
      }
    }
  },
  computed: {
    validation() {
      return this.form.name.length > 0;
    },

    textRace() {
      return this.t('Rennen');
    },

    textPlaceholderRace() {
      return this.t('Bitte Rennen eingeben');
    },
  },
   methods: {
    async onSubmit(evt) {
      evt.preventDefault()
      if (this.validation) {
        const backend = this.$store.state.backend;
        const result =  await backend.post(process.env.VUE_APP_BACKEND_URL + '/admin/race/create', {name : this.form.name});
        if (result.status == 'ok')
        {
          if (result.data.status == 'ok')
          {
            this.$notifications.success(this.t('Rennen wurde angelegt') + '!');
            this.$router.push({ name: "selectRace" });
          }
          else
           this.$notifications.error(result.data.message);
        }
        else
        {
          this.$notifications.error(result.message);
        }
      }
    },
    clearData() {
      this.form.name = '';
    },
    submissionOk() {
      return this.validation;
    },
  }
}
</script>

<style scoped>
</style>