<template>
  <div class="results">
    <pageTitle>{{ t('Starterliste') }}</pageTitle>
    <div class="clsGroupSelection" v-if="currentContent && currentContent.groups && currentContent.groups.length > 1">
      <b-form-select v-model="selectedGroup" :options="groupOptions"></b-form-select>
    </div>
    <LiveTimingDocument :document="currentContent" :selection="selectedGroup" />
  </div>
</template>

<script>
import LiveTimingDocument from "@/components/livetiming/LiveTimingDocument.vue";
import { liveTimingMixin } from "@/mixins/liveTimingMixin.js";
import { liveTimingGroupSelectionMixin } from "@/mixins/liveTimingGroupSelectionMixin.js";
import { translationMixin } from "@/mixins/translationMixin.js";
import { translationTableMixin} from "@/mixins/translations/views/Starters.js";
import { timingTranslationMixin } from "@/mixins/translations/live-timing/live-timing.js";

export default {
  mixins: [translationMixin, translationTableMixin, liveTimingMixin, liveTimingGroupSelectionMixin, timingTranslationMixin],
  components: {
    LiveTimingDocument
  },
  computed: {
    currentContent() {
      const result = JSON.parse(this.$store.getters['liveTiming/results']('Teilnehmer'));
      return result;
    },
  }
}
</script>