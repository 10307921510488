export const translationTableMixin = {
  data() {
    return {
      translation : {
         DEU : {
           "errorMessageChatSend" : "Name und Nachricht dürfen nicht leer sein"
         },
         ENG : {
           "Chat" : "Chatroom",
           "Name" : "Name",
           "Senden" : "Send",
           "Bitte Nachricht eingeben" : "Please enter your message",
           "als" : "as"
         },
         FRA : {
           "Chat" : "Tchat",
           "Name" : "Nom",
           "Senden" : "Envoyer",
           "Bitte Nachricht eingeben" : "Veuillez saisir un message",
           "als" : "comme"
         },
         ITA : {
           "Chat" : "Chat",
           "Name" : "Nome",
           "Senden" : "Send",
           "Bitte Nachricht eingeben" : "Inserisci un messaggio",
           "als" : "come"
         },
         CZE : {
           "Chat" : "Chat",
           "Name" : "Název",
           "Senden" : "Send",
           "Bitte Nachricht eingeben" : "Zadejte prosím zprávu",
           "als" : "jako"
         },
         POL : {
           "Chat" : "Czat",
           "Name" : "Nazwa",
           "Senden" : "Wyślij",
           "Bitte Nachricht eingeben" : "Proszę wpisać wiadomość",
           "als" : "jako"
         },
      }
    }
  },
}